import { useSelector } from "react-redux";
import { Routes, Route, useLocation, Navigate, useNavigate, useSearchParams } from "react-router-dom";
import NavBar from "./component/navBar/navBar";
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import TopBar from './component/topBar/TopBar';
import { useEffect, useState } from 'react';
import * as Pages from './pages/index';
import routes from './router/routes';
import { crm_param_action } from './store/crmParam/crmParamAction';
import { useDispatch } from 'react-redux';
import { document_action } from './store/documentDetails/document_action';
import { signId_action } from './store/signIDDetails/signId_action';
import { useIsMount } from "./utils/commonFunction";
import { folder_id_action } from "./store/folderId/folderIdAction";

const AppRoute = () => {
    const isLoggedIn = true;
    const location = useLocation();
    const isMount = useIsMount();
    const dispatch = useDispatch();

    const [toggle, setToggle] = useState(null);
    const [prePath, setPrePath] = useState('/');
    const [isInitFetched, setIsInitFetched] = useState(false);
    const [isFetchedSdkhome, setIsFetchedSdkhome] = useState(false);
    const [url_path, setUrl_path] = useState(null);
    const [documentId, setDocumentId] = useState(null);
    const [board_details, setBoard_details] = useState({ boardId: null, boardViewId: null, itemId: null });
    const [promo, setPromo] = useState(null);
    const loader = useSelector((state) => state?.loader);
    const signId_details = useSelector((state) => state?.signId_details?.value);
    const crm_param = useSelector((state) => state.crm_param.value);
    const digestDetails = useSelector((state) => state?.digestDetails?.value);
    const tableInfo = useSelector((state) => state?.adminAccountDetails);

    let reload = window.performance.getEntriesByType('navigation').map((nav) => nav.type);
    const navigationEntries = window.performance.getEntriesByType('navigation');
    const pathName = location.pathname.split('/').find(v => v === 'edit-as-new-template' || v === 'send-sign' || v === 'sign-myself');
    const crm_params = pathName ? window?.location?.href?.split('&')[2]?.split('=')[1] : window?.location?.href?.split('=')[1];
    const draftType = window?.location?.href?.split('&')[2]?.split('=')?.shift();
    const crm_param_url = draftType ? draftType : window?.location?.href?.split('?')[1]?.split('=')?.shift();
    const sdk_document_id = location.pathname?.split('/')[3];
    const sdk_org_id = window?.location?.href?.split('#')[1]?.split('/')[1]?.split('c')[1];
    const file_id = window.location?.href?.split('=')[1]?.split('&')[0];
    const draft_approval = window.location.href?.split('&')?.find(s => s === 'action=draft' || s === 'action=approval' || s === 'action=approved' || s === 'action=edit');

    let enabled_init = ['home', 'my-documents', 'upload', 'sign-myself', 'send-sign', 'templates', 'settings', 'contacts', 'integrations', 'activity', 'document', 'edit-as-new-template', 'edit-template', 'price-list', 'payment', 'send-sign', 'sign-myself'];
    let list = ['admin-tool','document', 'edit-as-new-template', 'edit-template', 'sign-request', 'sdkhome', 'my-document', 'upload-document', 'book-demo', 'bookdemo', 'invitation', 'centilio-docs', 'how-to-use', 'howtouse', 'pdf-converter', 'price-list', 'payment'];
    const init_disable = ['sign-request', 'book-demo', 'bookdemo', 'centilio-docs', 'admin-tool', 'how-to-use', 'howtouse', 'blog', 'pdf-converter', 'wrong-page'];
    const disableTopNav = location.pathname?.split('/')?.find((v) => list.indexOf(v) !== -1);
    const sdkPath = location.pathname?.split('/')[1];
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const board_Id = searchParams.get('boardId');
    const boardViewId = searchParams.get('boardViewId');
    const itemId = searchParams.get('itemId');
    const hubspot_crm = searchParams.get('crmparam');
    const grand_admin_access = tableInfo?.tabledata?.grand_admin_access
    const resource = searchParams.get('resource');
    const resource_id = searchParams.get('resource_id');
    const source = searchParams.get('source');

    useEffect(() => {
        if (sdkPath === 'sdkhome' && ((board_Id && (boardViewId || itemId)) || hubspot_crm || resource)) {
            setBoard_details({ boardId: board_Id, boardViewId: itemId ? itemId : '', itemId: itemId, crmParam: hubspot_crm?.split("#")[0], resource: resource, resource_id: resource_id, source: Number(source) });
            navigate(`?boardId=${board_Id}&boardViewId=${boardViewId}`);
        }

        if (window?.location?.href?.split('?')[1]?.split('=')[0] === 'promo_code') {
            setPromo(window?.location?.href?.split('?')[1]?.split('=')[1]);
        };
    }, []);

    useEffect(() => {
        if (crm_param_url === 'crmparam' || sdkPath === 'sdkhome') {
            if (pathName) {
                navigate(window.location.hash.split('&crmparam')[0].split('#')[1]);
                let crm_template = { Template_Id: sdk_document_id, Org_Id: sdk_org_id, Id: file_id, crm_param: 'crm_draft' };
                dispatch(signId_action(crm_template));
            } else {
                let crm_param = crm_params?.split('&source')[0];
                dispatch(crm_param_action(crm_param));
                dispatch(document_action(null));
            }
        } else {
            dispatch(crm_param_action(null));
        }
    }, []);

    useEffect(() => {
        if (isMount || location?.pathname === '/') return
        setUrl_path(window?.location?.href?.split('/')[4]);

        let nav_refresh = window?.location?.href;
        if (nav_refresh?.split('/')[5] === 'document') setDocumentId(nav_refresh?.split('/')[6])
        let doc_ = nav_refresh?.split('/')[5] === 'document' || nav_refresh?.split('/')[5] === 'edit-template' || nav_refresh?.split('/')[5] === 'edit-as-new-template' || nav_refresh?.split('/')[5] === 'send-sign' || nav_refresh?.split('/')[5] === 'sign-myself';
        let dis_refresh = nav_refresh?.split('/')?.length >= 6 && nav_refresh?.split('/')[4]?.substring(0, 1);
        if ((nav_refresh?.split('/')[4] !== url_path || (doc_ && nav_refresh?.split('/')[6] !== documentId && documentId)) && reload[0] !== 'reload' && sdkPath !== 'sdkhome' && sdkPath !== 'sdk') {
            if (nav_refresh?.split('#')[1]?.split('/')[1] !== 'error-404' && dis_refresh && !location?.state?.switch_org_reload && draft_approval !== 'action=approval') {
                window.location.reload(true);
            }
        };

        if ((nav_refresh?.split('#')[1]?.split('/').length <= 2 || (nav_refresh?.split('#')[1]?.split('/').length === 3 && nav_refresh?.split('#')[1]?.split('/')[2] === '')) && nav_refresh?.split('#')[1]?.split('/')[1] !== 'error-404') {
            if (init_disable.indexOf(location.pathname?.split('/')?.find(s => s)) === -1 && sdkPath !== 'sdkhome' && sdkPath !== 'sdk') navigate('/');
        };
    }, [navigationEntries]);

    useEffect(() => {
        if (sdkPath === 'sdkhome' && !isFetchedSdkhome) {
            setIsFetchedSdkhome(true);
        } else if (crm_param_url === 'crmparam' || signId_details?.crm_param) {
            setIsInitFetched(true);
        }
    }, [navigationEntries]);

    useEffect(() => {
        if (reload[0] === 'reload') {
            if (init_disable.indexOf(location.pathname?.split('/')?.find(s => s)) !== -1) {
                if (crm_params && crm_param_url !== 'digest') {
                    let crm_param = crm_params?.split('&source')[0];
                    navigate(`/${location.pathname?.split('/')?.find(s => s)}?crmparam=${crm_param}`);
                    dispatch(crm_param_action(crm_param));
                } else if (crm_param_url === 'digest') {
                    navigate(`/${location.pathname?.split('/')?.find(s => s)}?digest=${digestDetails}`);
                } else {
                    navigate(`/${location.pathname?.split('/')?.find(s => s)}`);
                }
            } else {
                if (location.pathname === '/error-404') setPrePath('/');
                else {
                    if (window.location.href.split('source=')[1] !== '2') setPrePath(location.pathname);
                    else setPrePath(window?.location?.href?.split('#')[1]);

                    if (window?.location?.href?.split('/')[5] === 'my-documents') dispatch(folder_id_action(null));
                }
                navigate('/');
            };

            if (crm_param || signId_details?.crm_param) {
                navigate(navigationEntries[0]?.name?.split('#')[1]);
            };
        }
    }, []);

    useEffect(() => {
        if (!isInitFetched && enabled_init?.indexOf(location?.pathname?.split('/')[2]) !== -1) {
            setPrePath(location.pathname);
            navigate('/');
        } else {
            if (!isInitFetched && window.location.href?.split('/')?.length === 5 && init_disable.indexOf(location.pathname?.split('/')?.find(s => s)) === -1 && location?.pathname !== '/sdkhome') {
                let org_value = window.location.href.split('/')[4].split('c')[1];
                navigate('/', { state: { org_id: org_value } });
            };
        };

        if (location?.pathname === '/sdkhome') {
            setPrePath(location.pathname);
            navigate('/');
        };
    }, []);

    return (
        <>
            {loader.isLoading && (
                <Box sx={{ margin: 0, padding: 0 }}>
                    <LinearProgress value={20} valueBuffer={10} sx={{ position: 'fixed', top: 0, left: 0, width: '100%', zIndex: 1300 }} />
                </Box>
            )}
            {isLoggedIn ? (
                <div className='main_wrap'>
                    {list.indexOf(disableTopNav) === -1 && !draft_approval && <NavBar isFetched={isInitFetched} expand={toggle} />}
                    <div style={{ width: '100%', overflow: 'hidden', position: 'relative' }}>
                        {list.indexOf(disableTopNav) === -1 && !draft_approval && <TopBar isFetched={isInitFetched} expand={toggle} setIsNavExpand={setToggle} />}
                        <div className='main_content'>
                            <Routes>
                                <Route path="/*" element={<Navigate to='/error-404' />} />
                                <Route path="/error-404" element={<Pages.ErrorPage />} />
                                <Route path="/" exact element={<Pages.OrgInit prePath={prePath} isFetchedSdkhome={(val) => setIsFetchedSdkhome(val)} isFetched={(fetched) => setIsInitFetched(fetched)} promo={promo} />} />
                                <Route path=":id">
                                    {routes.map((route, i) => {
                                        const { path, component, exact } = route;
                                        const Page = Pages[component];

                                        return (
                                            <Route key={i} exact={exact} path={path} element={isInitFetched ? <Page /> : null} />
                                        )
                                    })}
                                </Route>
                                <Route exact path='/sign-request' element={<Pages.SignDocument />} />
                                <Route exact path='/sdkhome' element={isFetchedSdkhome ? <Pages.MondayCRM board_detail={board_details} /> : null} />
                                <Route path='/sdk'>
                                    <Route path='my-document' element={<Pages.SendSign />} />
                                    <Route exact path='upload-document' element={<Pages.Upload />} />
                                    <Route path='my-document/:id' element={<Pages.SendDetails />} />
                                </Route>
                                <Route
                                    exact
                                    path="/create-templates"
                                    element={<Pages.CreateTemplates />}
                                />
                                <Route exact path='/book-demo' element={<Pages.EventSchedule />} />
                                <Route exact path='/bookdemo' element={<Pages.EventSchedule />} />
                                <Route exact path='/invitation' element={<Pages.Invitation />} />
                                <Route exact path='/admin-tool' element={<Pages.AdminTool />} />
                                <Route exact path='/centilio-docs' element={<Pages.CentilioDoc />} />
                                <Route exact path='/how-to-use' element={<Pages.HowToUse />} />
                                <Route exact path='/howtouse' element={<Pages.HowToUse />} />
                                <Route exact path='/blog' element={<Pages.Blog />} />
                                <Route exact path='/pdf-converter' element={<Pages.PdfConverter />} />
                                <Route exact path='/wrong-page' element={<Pages.WrongPage />} />
                                <Route exact path='/email-campaign' element={<Pages.EmailCampaign />} />
                            </Routes>
                        </div>
                    </div >
                </div >
            ) : (
                <Routes></Routes>
            )}
        </>
    );
};

export default AppRoute;