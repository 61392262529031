import React, { useEffect, useState } from 'react'
import { Box, Button, FormControl, MenuItem, OutlinedInput, Select } from '@mui/material'
import { toast } from 'react-toastify';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { toggleLoader } from '../store/loader/loaderReducer';
import { ReusableButton, ReusableLabel, ReusableSelect, ReusableTextField } from './Fields/AllFields';
import styles from "./AllowFeature.module.css";

const Banner = ({ userId, bannerInfo }) => {

    const dispatch = useDispatch();
    const [banner, setBanner] = useState({ userid: userId, status: 0, bannerKey: '' })

    const StatusOptions = [
        { value: 0, label: 'SHOW_BANNER' },
        { value: 1, label: 'HIDE_BANNER' },
    ];

    const BannerOptions = Object.keys(bannerInfo).map((key, index) => ({
        value: banner.bannerKey,
        label: bannerInfo[key],
    }));

    useEffect(() => {
        setBanner({ ...banner, bannerKey: bannerInfo[1] })
    }, [])

    const handleSubmit = () => {
        let data = {
            "banner_key": banner.bannerKey,
            "user_id": banner.userid,
            "status": banner.status,
        }

        dispatch(toggleLoader(true));
        axios.put(process.env.REACT_APP_USER_BANNER, data, { withCredentials: process.env.REACT_APP_CREDENTIALS })
            .then((res) => {
                dispatch(toggleLoader(false));
            }).catch((err) => {
                dispatch(toggleLoader(false));
                toast.error(err.response.data);
            })
    }

    return (

        <Box sx={{ width: "50%" }}>
            <Box className={styles.grid_item}>
                <Box className={styles.grid_text_label}>
                    <ReusableLabel text="User Id" />
                    <ReusableTextField type='Number' value={banner.userid} onChange={(e) => setBanner({ ...banner, userid: Number(e.target.value) })} placeholder='Enter User Id' />
                </Box>
                <Box className={styles.grid_text_label}>
                    <ReusableLabel text="Status" />
                    <ReusableSelect options={StatusOptions} value={banner.status} onChange={(e) => setBanner({ ...banner, status: Number(e.target.value) })} />
                </Box>
                <Box className={styles.grid_text_label}>
                    <ReusableLabel text="Banner Key" />
                    <ReusableSelect options={BannerOptions} value={banner.bannerKey} onChange={(e) => setBanner({ ...banner, bannerKey: e.target.outerText, })} />
                </Box>
                <Box sx={{ textAlign: 'end', paddingBottom: "10px" }}>
                    <ReusableButton variant="contained" label={'Submit'} onClick={handleSubmit} />
                </Box>

            </Box>

        </Box>
    )
}

export default Banner