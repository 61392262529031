import React, { useEffect, useRef, useState } from 'react'
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Box, Button, FormControl, MenuItem, Popover, Select, TextField } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ClearIcon from '@mui/icons-material/Clear';
import { ReusableButton, ReusableDatePicker, ReusableDateTimePicker, ReusableLabel, ReusableSelect, ReusableTextField } from '../../Admin/Fields/AllFields';
import styles from "./FilterPopover.module.css";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { useSelector } from 'react-redux';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const OperatorOptions = [
    { value: 'and', label: 'AND' },
    { value: 'or', label: 'OR' },
];




const FilterUserId = ({ name, id, open, anchorEl, onClose, orgtHead, setfilterdata, selectedHead, data, filters, setfilters, filterData ,clear}) => {

    const [expand, setExpand] = useState(false);
    const [idCount, setIdCount] = useState(1);
    // const [filters, setfilters] = useState([{ id: 1, columns: '', condition: '', value: [], operator: 'and', type: '', comparison_operator: '' }])
    const [isHovered, setIsHovered] = useState(false);






    const statusValue = ['AWAIT', 'APPROVED', 'SENT', 'FAILED', 'LATER', 'SEND_NOW'];
    const dateTypes = ['TODAY', 'YESTERDAY', 'THIS_WEEK', 'LAST_WEEK', 'THIS_MONTH', 'LAST_MONTH', 'THIS_YEAR', 'LAST_YEAR'];
    const DateValues = ["Start_Time", "Created_Time", "Modified_Time", 'Timestamp'];

    const comparisonOperator = data.comparison_operators


    const handilConditionOptions = (e) => {

        const val = e ? e : ColumnsOptionsSelected[0].type;
        const conditions = data.type_operation[val]



        return (conditions?.map(condition => ({
            value: condition,
            label: condition
        }))
        )

    }

    // const ColumnsOptions = orgtHead.map((table) => ({
    //     value: table,
    //     label: table,
    // }));

    const ColumnsOptionsSelected = Object.values(selectedHead).map((item, i) => ({
        value: item.display_name,
        label: item.display_name,
        type: item.type,
    }));


    const StatusOptions = statusValue.map((table, i) => ({
        value: i,
        label: table,
    }));

    const DateTypesOptions = dateTypes.map((table, i) => ({
        value: i + 1,
        label: table,
    }));


    useEffect(() => {
        const value = filters;
        value[0].columns = ColumnsOptionsSelected[0].value;
        value[0].value = [1];
        value[0].type = ColumnsOptionsSelected[0].type;
        value[0].condition = data.type_operation[ColumnsOptionsSelected[0].type][0]
        value[0].comparison_operator = comparisonOperator[data.type_operation[ColumnsOptionsSelected[0].type][0]]
        setfilters(value)
    }, []);

    useEffect(() => {
        if (filterData.length === 0) {
            setfilters([{ id: 1, columns: ColumnsOptionsSelected[0].value, condition: data.type_operation[ColumnsOptionsSelected[0].type][0], value: [1], type: ColumnsOptionsSelected[0].type, comparison_operator: comparisonOperator[data.type_operation[ColumnsOptionsSelected[0].type][0]] }])
        }
    }, [filterData]);



    const handleSetColumns = (id, value, index) => {


        let selectedColumn = ColumnsOptionsSelected.find(col => col.value === value);

        const updatedInputs = filters.map((filter) =>
            filter.id === id ? { ...filter, columns: value, type: selectedColumn.type } : filter
        );

        setfilters(updatedInputs);
    }

    const handleSetCondition = (id, value) => {


        const updatedInputs = filters.map((filter) =>
            filter.id === id ? { ...filter, condition: value, comparison_operator: comparisonOperator[value] } : filter
        );
        setfilters(updatedInputs);
    }

    const handleSetValue = (id, value) => {

        setfilters(prevFilters =>
            prevFilters.map(filter =>
                filter.id === 1
                    ? { ...filter, value: [{ from: 1738229483532, to: 1738229632236 }] }
                    : filter
            )
        );

        const updatedInputs = filters.map((filter) =>
            filter.id === id ? { ...filter, value: [value] } : filter
        );
        setfilters(updatedInputs);
    }
    const handleSetDateValue = (id, value) => {

        const updatedInputs = filters.map((filter) =>
            filter.id === id ? { ...filter, value: [{ from: value, to: value + 24 * 60 * 60 * 1000 }] } : filter
        );
        setfilters(updatedInputs);
    }

    const handleSetOperator = (id, value) => {
        const updatedInputs = filters.map((filter) =>
            filter.id === id ? { ...filter, operator: value } : filter
        );
        setfilters(updatedInputs);
    }

    const handleAddfilter = (e) => {
        setIdCount(idCount + 1)
        const newFilter = { id: idCount + 1, columns: ColumnsOptionsSelected[0].value, condition: data.type_operation[ColumnsOptionsSelected[0].type][0], value: 0, operator: "and", type: ColumnsOptionsSelected[0].type, comparison_operator: comparisonOperator[data.type_operation[ColumnsOptionsSelected[0].type][0]] };
        setfilters([...filters, newFilter]);
    }

    const handleRemoveFilter = (id) => {
        const updatedInputs = filters.filter((filter) => filter.id !== id);
        setfilters(updatedInputs);
    }


    const sendDataToTable = () => {
        setfilterdata(filters.map((filter) => {
            if (filters[0].id === filter.id) {
                const { operator, ...rest } = filter;
                return rest;
            }
            return filter;
        }))

        onClose()
        setExpand(false);
    }

    const clearFilter = () => {
        setExpand(false);
        setfilters([{ id: 1, columns: ColumnsOptionsSelected[0].value, condition: data.type_operation[ColumnsOptionsSelected[0].type][0], value: [1], type: ColumnsOptionsSelected[0].type, comparison_operator: comparisonOperator[data.type_operation[ColumnsOptionsSelected[0].type][0]] }])
        setfilterdata([])
        onClose()
        clear()

    }


    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Box sx={{ padding: "10px", maxHeight: '300px' }}>
                {filters.map((filter, i) => (
                    <Box className={styles.popover_filter_top}>
                        {(filters[0].id === filter.id ?
                            <Box><ReusableLabel width='150px' text="Where" /></Box> :
                            <Box><ReusableSelect options={OperatorOptions} width='80px' value={filter.operator} onChange={(e) => handleSetOperator(filter.id, e.target.value)} /></Box>
                        )}
                        <Box> <ReusableSelect defaultValue={0} options={ColumnsOptionsSelected} width='150px' value={filter.columns} onChange={(e) => handleSetColumns(filter.id, e.target.value, e)} /></Box>
                        {/* <Box> <ReusableSelect options={ConditionOptions} width='150px' value={filter.condition} onChange={(e) => handleSetCondition(filter.id, e.target.value)} /></Box> */}
                        <Box> <ReusableSelect options={handilConditionOptions(filter.type)} width='150px' value={filter.condition} onChange={(e) => handleSetCondition(filter.id, e.target.value)} /></Box>
                        {(filter.columns === "Created_Time" || filter.columns === "Modified_Time") ?
                            <Box>
                                <ReusableDateTimePicker width={"200px"}  onChange={(e) => handleSetDateValue(filter.id, dayjs(e.$d).valueOf())} />
                            </Box>
                            : name === "Email camp" ? filter.columns === "Status" ?
                                <Box>
                                    <ReusableSelect  defaultValue={0} options={StatusOptions} value={filter.value} onChange={(e) => handleSetValue(filter.id, e.target.value)} />
                                </Box> :

                                DateValues.includes(filter.columns) ?
                                    <Box>
                                        <ReusableSelect defaultValue={1} options={DateTypesOptions} value={filter.value} onChange={(e) => handleSetValue(filter.id, e.target.value)} />
                                    </Box> :
                                    <Box>
                                        <ReusableTextField  value={filter["value"][0]} onChange={(e) => handleSetValue(filter.id, e.target.value)} placeholder='Value' />
                                    </Box> :
                                <Box>
                                    <ReusableTextField  value={filter["value"][0]} onChange={(e) => handleSetValue(filter.id, e.target.value)} placeholder='Value' />
                                </Box>}
                        {i >= 1 && <Box sx={{ opacity: i >= 1 ? 1 : 0 }} className={styles.popover_filter_Delete} onClick={() => handleRemoveFilter(filter.id)} >  <ClearIcon /> </Box>}

                    </Box>
                ))}
                <Box display={{ display: "flex", justifyContent: 'space-between', paddingBottom: '10px' }}>
                    <Box className={styles.popover_filter_add_field} onClick={handleAddfilter} >
                        <Box sx={{ display: "flex", alignItems: "center" }}><ControlPointIcon /></Box>
                        <Box sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}>Add Field</Box>
                    </Box>
                    <Box className={styles.popover_filter_add_field}  >
                        <ReusableButton variant="contained" onClick={() => clearFilter()} label={'Clear'} />
                        <ReusableButton variant="contained" onClick={sendDataToTable} label={'Apply'} />
                    </Box>
                </Box>

            </Box>
        </Popover >
    )
}

export default FilterUserId