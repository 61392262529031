import React, { useEffect, useRef, useState } from "react";
import PDFFields from "./PDFFields";
import PDFPreview from "./PDFPreview";
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import './styles/PDFData.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import PDFDragField from "./PDFDragField";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { template_preview_action } from "../store/templatePreview/templatePreviewAction";
import { toggleLoader } from "../store/loader/loaderReducer";
import { useLocation, useNavigate } from "react-router-dom";
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { toast } from "react-toastify";
import { getTemplatePreview } from "../Api/TemplatePreviewApi";
import { replaceToNumber } from '../utils/commonFunction'
import moment from "moment";
import { document_action } from "../store/documentDetails/document_action";
import { signId_action } from "../store/signIDDetails/signId_action";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

let width = 110;
let height = 26;

const field_constants = ['Name', 'Multiline', 'Radio', 'Checkbox', 'Dropdown', 'Url', 'Date', 'Email', 'Signature', 'Company', 'Initial', 'Text', 'Number', 'Title'];

const PDFData = ({ fileLoader, show_Modal, selectColor, recipient, getPasscode, select_Recipients, signerType, handleRecipientHandler, controls_, pageInfo_, signType, signerCheckBox_, closeModal, signOrder, getUserId, approvals_, approvals_list, selectFieldRecipient, selectRecipientColor, recipientShow }) => {
    const [numPages, setNumPages] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [activeField, setActiveField] = useState({ show: false });
    const [scroll, setScroll] = useState(0);
    const [controls, setControls] = useState({});
    const [state, setState] = useState([]);
    const [file, setFile] = useState(null);
    const [recipientList, setRecipientList] = useState([]);
    const [selectColors, setSelectColors] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectRecipients, setSelectRecipients] = useState(null);
    const [getSignType, setGetSignType] = useState(null);
    const [signerCheckBox, setSignerCheckBox] = useState(false);
    const [pageInput, setPageInput] = useState(1);
    const [scale, setScale] = useState({ width: 700, height: 500 });
    const [isOrder, setIsOrder] = useState(false);
    const [preFormat, setPreFormat] = useState('DD/MM/YYYY');
    const [password, setPassword] = useState(null);
    const [approvals, setApprovals] = useState(false);
    const [user_id, setUser_id] = useState([]);
    const [pulse_detail, setPulse_detail] = useState([]);
    const [pageProperty, setPageProperty] = useState({ width: 700, height: 900 });
    const [openSign, setOpenSign] = useState(false);

    // const [dimensions_info, setDimensions_info] = useState({ width: null, height: null });
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const document_details = useSelector((state) => state?.document_details?.value);
    const templatePreview = useSelector((state) => state?.templatePreview?.value);
    const signId_details = useSelector((state) => state?.signId_details?.value);
    const crm_param = useSelector((state) => state.crm_param.value);
    const show_integeration = useSelector((state) => state.welcomeImgDetail.value);

    const refCanvas = useRef();
    const refDocument = useRef();
    const textAreaRefs = useRef([]);

    const pathName = location.pathname?.split('/')?.find(v => v === 'edit-template' || v === 'edit-as-new-template' || v === 'send-sign' || v === 'sign-myself');
    const path = location.pathname?.split('/')?.find(v => v === 'document');
    const sdk_org_id = window?.location?.href?.split('#')[1]?.split('/')[1]?.split('c')[1];
    const document_id = window?.location?.href?.split('?')[0]?.split('/')[6];
    let reload = window.performance.getEntriesByType('navigation').map((nav) => nav.type);
    const draftType = window.location.href?.split('&')?.find(s => s === 'action=draft' || s === 'action=approval' || s === 'action=approved' || s === 'action=edit');

    const onDocumentLoadSuccess = async (numPages) => {
        dispatch(toggleLoader(false));
        setNumPages([numPages?.numPages]);
        fileLoader(true);
        setTimeout(() => {
            handlePageProperties();
        }, 100);
    };

    useEffect(() => {
        setShowModal(show_Modal);
    }, [show_Modal]);

    useEffect(() => {
        if (approvals) setApprovals(approvals_list);
    }, [approvals_list]);

    useEffect(() => {
        if (crm_param) {
            dispatch(toggleLoader(true));
            axios.get(process.env.REACT_APP_CRM_BOARD_DATA, { withCredentials: process.env.REACT_APP_CREDENTIALS, params: { crm_param: crm_param } })
                .then((res) => {
                    if (res?.data?.success) {
                        let data = res?.data?.data?.items[0]?.columns;
                        const sortedData = [
                            ...data.filter(item => item.value !== ""),
                            ...data.filter(item => item.value === "")
                        ];
                        setPulse_detail(sortedData);
                    }
                    dispatch(toggleLoader(false));
                })
                .catch((err) => {
                    toast.error(err.message);
                    dispatch(toggleLoader(false));
                });
        }
    }, [crm_param]);

    useEffect(() => {
        const doc_reload = location.state;
        let id = { Org_Id: sdk_org_id, Template_Id: document_id, Id: signId_details?.id };
        if (!path) {
            if (((signId_details?.crm_param === 'crm_draft' || draftType) && doc_reload !== 'document_reload') || ((pathName || path) && doc_reload !== 'document_reload')) {
                getTemplatePreview(id, dispatch, (res) => {
                    if (res?.data?.template_information?.Type === 2) dispatch(signId_action(res?.data?.sign_request_info));
                    let field_value = JSON.parse(res.data.template_information.Properties);
                    let status = res?.data?.sign_request_info?.status;
                    const array = field_value?.properties?.approvers?.map(elem => elem.user_id);
                    let approver_login = array?.includes(show_integeration?.user_information?.Id);
                    if ((pathName || draftType) && array?.length) {
                        let id_ = res?.data?.sign_request_info?.id;
                        if (field_value?.properties?.approvers?.length && approver_login) {
                            switch (status) {
                                case 9:
                                    navigate(`?id=${id_}&action=approval`);
                                    break;
                                case 10:
                                    navigate(`?id=${id_}&action=approved`);
                                    break;
                                case 11:
                                    navigate(`?id=${id_}&action=edit`);
                                    break;
                                case 6:
                                    navigate(`?id=${id_}&action=draft`);
                                    setApprovals(false);
                                    approvals_(false);
                                    break;
                                default:
                                    break;
                            }
                        } else {
                            if (field_value?.properties?.approvers?.length && status === 9) navigate(`?id=${id_}&action=edit`);
                            else if (field_value?.properties?.approvers?.length && status === 10) navigate(`?id=${id_}&action=approved`);
                            else if (field_value?.properties?.approvers?.length && status === 6) navigate(`?id=${id_}&action=draft`);
                        }
                    } else {
                        if ((pathName === 'send-sign' || pathName === 'sign-myself') || draftType) navigate(`?id=${res?.data?.sign_request_info?.id ?? id?.Id}&action=draft`);
                    }
                }, (err) => {
                    dispatch(toggleLoader(false));
                    if (err?.response?.data === 'template_id_not_found' || err?.response?.data?.template_id === 'template_id is invalid') {
                        toast.error('Access Denied');
                        navigate('/error-404');
                    } else {
                        toast.error(err.message);
                    }
                })
            } else {
                // window.history.replaceState({}, document.title);
            };
        } else {
            if (crm_param || (crm_param && reload[0] === 'reload')) {
                navigate(`?crmparam=${crm_param}`);
            };
        }
    }, []);

    useEffect(() => {
        setSelectColors(selectColor);
        setRecipientList(recipient);
        setSelectRecipients(select_Recipients);
        if (recipientShow) setActiveField({ show: false });
    }, [recipient, selectColor, select_Recipients, recipientShow]);

    useEffect(() => {
        let data = document_details?.document_information ? document_details?.document_information : document_details?.item;
        let list = {};
        let position = templatePreview?.properties ? templatePreview?.properties : templatePreview;
        let hidden_properties = templatePreview?.hidden_proerties;
        let item = position?.field_properties ? Object.keys(position?.field_properties)?.length !== 0 : null;
        let getSignType = document_details?.template_information?.Type === 2 ? 'Send to others' : 'Sign myself';
        if (item) {
            let get_field_position = Object.keys(position?.field_properties);
            get_field_position?.map((item) => {
                let arr = [];
                let fields = position?.field_properties[item];
                let fields_position = position?.field_positions_object;
                let fields_name = Object.keys(fields);
                let format_object = position?.format_object;
                let default_format = position?.default_format_object;

                let radioOption = [];
                let checkboxOption = [];
                let dropdownOption = [];

                fields_name.map((list) => {
                    const isCheckBox = fields[list]?.field_value?.replace(/[\d'']+/g, '') === 'Checkbox '
                    const isDropDown = fields[list]?.field_value?.replace(/[\d'']+/g, '') === 'Dropdown '
                    const isRadio = fields[list]?.field_value?.replace(/[\d'']+/g, '') === 'Radio '
                    const isSignature = fields[list]?.field_value?.replace(/[\d'']+/g, '') === 'Signature '
                    const isText = fields[list]?.type === 12

                    if (isCheckBox || isRadio) {
                        const curData = {
                            ...fields[list],
                            field_key: !fields[list]?.board_details ? field_constants[fields[list]?.type - 1] : list,
                            width: fields_position[list]?.width,
                            height: fields_position[list]?.height,
                            background: fields_position[list]?.background,
                            type: fields[list]?.type,
                            sign_type: getSignType
                        };

                        let optionValue = fields[list]?.field_value?.split(' ')[0].concat('_value').toLowerCase()

                        const activeOption = isCheckBox ? checkboxOption : radioOption
                        const curIdx = activeOption.findIndex((o) => o.group_id === curData.group_id);
                        const dataLen = activeOption.length;

                        if (curIdx === -1) {
                            activeOption[dataLen] = { ...curData, options: [curData[optionValue]] };
                        } else {
                            activeOption[curIdx] = {
                                ...activeOption[curIdx],
                                options: [...activeOption[curIdx].options, curData[optionValue]]
                            }
                        };
                    } else if (isDropDown) {
                        return dropdownOption.push({
                            key: fields[list]?.key,
                            field_key: !fields[list]?.board_details ? field_constants[fields[list]?.type - 1] : list,
                            field_value: fields[list]?.field_value,
                            value: fields[list]?.field_value,
                            options: fields[list]?.dropdown_value,
                            width: fields_position[list]?.width,
                            height: fields_position[list]?.height,
                            top: fields_position[list]?.top + 9,
                            left: fields_position[list]?.left - 2,
                            background: fields_position[list]?.background?.replace(';', ''),
                            type: fields[list]?.type,
                            sign_type: getSignType,
                            fontSize: fields[list]?.is_format_object ? format_object[list]?.font_size : default_format?.font_size,
                            fontWeight: fields[list]?.is_format_object ? format_object[list]?.bold ? 'bold' : 'normal' : default_format?.bold ? 'bold' : 'normal',
                            fontStyle: fields[list]?.is_format_object ? format_object[list]?.italic ? 'italic' : 'normal' : default_format?.italic ? 'italic' : 'normal',
                            alignment: fields[list]?.is_format_object ? format_object[list]?.alignment : default_format?.alignment,
                            fontFamily: fields[list]?.is_format_object ? format_object[list]?.font_family : default_format?.font_family,
                            color: fields[list]?.is_format_object ? format_object[list]?.color?.value : default_format?.color,
                            is_format_object: fields[list]?.is_format_object,
                            board_details: fields[list]?.board_details ? fields[list]?.board_details : null
                        })
                    } else {
                        return arr.push({
                            key: fields[list]?.key,
                            field_key: !fields[list]?.board_details ? field_constants[fields[list]?.type - 1] : list,
                            field_value: isText ? list?.replace('_', ' ') : fields[list]?.field_value,
                            initial_value: fields[list]?.field_value,
                            value: fields[list]?.default_value,
                            width: fields_position[list]?.width,
                            height: fields_position[list]?.height,
                            top: isSignature ? fields_position[list]?.top : fields_position[list]?.top + 9,
                            left: isSignature ? fields_position[list]?.left : fields_position[list]?.left - 2,
                            background: fields_position[list]?.background?.replace(';', ''),
                            type: fields[list]?.type,
                            sign_type: getSignType,
                            date_format: fields[list]?.date_format,
                            is_required: fields[list]?.is_required,
                            is_read_only: fields[list]?.is_read_only,
                            fontSize: fields[list]?.is_format_object ? format_object[list]?.font_size : default_format?.font_size,
                            fontWeight: fields[list]?.is_format_object ? format_object[list]?.bold ? 'bold' : 'normal' : default_format?.bold ? 'bold' : 'normal',
                            fontStyle: fields[list]?.is_format_object ? format_object[list]?.italic ? 'italic' : 'normal' : default_format?.italic ? 'italic' : 'normal',
                            alignment: fields[list]?.is_format_object ? format_object[list]?.alignment : default_format?.alignment,
                            fontFamily: fields[list]?.is_format_object ? format_object[list]?.font_family : default_format?.font_family,
                            color: fields[list]?.is_format_object ? format_object[list]?.color?.value : default_format?.color,
                            is_format_object: fields[list]?.is_format_object,
                            board_details: fields[list]?.board_details
                        })
                    }
                })

                const sortRadio = radioOption.map(o => {
                    const { radio_value, ...restData } = o
                    const sorted = restData.options.sort((a, b) => a.id - b.id)
                    return { ...restData, options: sorted, top: sorted[0].top, left: sorted[0].left }
                })

                const sortCheckBox = checkboxOption.map(o => {
                    const { checkbox_value, ...restData } = o
                    const sorted = restData.options.sort((a, b) => a.id - b.id)
                    return { ...restData, options: sorted, top: sorted[0].top, left: sorted[0].left }
                })
                list[item] = [...sortRadio, ...sortCheckBox, ...dropdownOption, ...arr];
            })
        }

        if (pathName) {
            dispatch(toggleLoader(true));
            handleGetDocumentFile(data, position);
        } else {
            dispatch(toggleLoader(true));
            axios.get(process.env.REACT_APP_DOCUMENTPREVIEW, { withCredentials: process.env.REACT_APP_CREDENTIALS, params: { document_id: document_id, org_id: sdk_org_id } })
                .then((res) => {
                    let data = res?.data?.document_information;
                    if (crm_param) dispatch(document_action({ item: data }));
                    handleGetDocumentFile(data, position);
                    // setDimensions_info(data?.Information?.dimensions_info);
                }).catch((e) => {
                    dispatch(toggleLoader(false));
                    if (e?.response?.data === 'access_denied' || e?.response?.data?.document_id === 'document_id is invalid') {
                        toast.error('Access Denied');
                        navigate('/error-404');
                    } else {
                        toast.error(e.message);
                    }
                });
        }
        setControls(list);
        setPassword(hidden_properties);
        getPasscode(hidden_properties?.password_keys);
        if (position?.approvers?.length && (draftType === 'action=approval' || draftType === 'action=approved' || draftType === 'action=edit' || pathName)) {
            setApprovals(true);
            approvals_(true);
            setUser_id(position?.approvers);
        } else {
            if (position?.approvers?.length && draftType === 'action=draft') setUser_id(position?.approvers);
        }
        if (Object.keys(list)?.length) {
            setGetSignType(getSignType);
            setSignerCheckBox(getSignType === 'Sign myself');
            setIsOrder(position?.is_order);
            signerType(getSignType);
        };

    }, [document_details?.document_information]);

    const handleGetDocumentFile = (data, position) => {
        if (data) {
            dispatch(toggleLoader(true));
            axios.get(process.env.REACT_APP_DOCUMENTFILE, { responseType: 'blob', withCredentials: process.env.REACT_APP_CREDENTIALS, params: { file_id: data?.File_Id, org_id: sdk_org_id } })
                .then((res) => {
                    setTimeout(() => {
                        setFile(res.data);
                    }, 0);
                    setRecipientList(position?.recipient_info);
                    handleRecipientHandler(position?.recipient_info ?? []);
                    dispatch(template_preview_action({}));
                }).catch((e) => {
                    toast.error(e.message);
                    dispatch(toggleLoader(false));
                });
        }
    };

    useEffect(() => {
        controls_(controls);
    }, [controls]);

    const handleDrop = (e, drop = false, pages) => {
        const data = e?.dataTransfer?.getData('text/plain');
        let details = controls[pages] || [];

        const admin = show_integeration?.user_information?.userRole.includes('Super Admin', 'Admin');
        let conditionCheck = admin || show_integeration?.user_permission[3]?.create === 1 || show_integeration?.user_permission[4]?.create === 1 || show_integeration?.user_permission[2]?.create === 1;

        if (!drop && !details?.includes(data) && (data?.length !== 0 ? Object.keys(JSON.parse(data))?.length : false)) {
            let item = JSON.parse(data);
            const obj = [...state, item];
            setState(cur => ([...cur, item]));

            let length;
            if (pathName) {
                let arr = [];
                Object.keys(controls)?.filter((k) => {
                    controls[k]?.map((s) => {
                        if (s?.field_key === item?.key) {
                            arr.push(s.field_value.split(' ')[1]);
                        }
                    })
                });
                length = arr?.length ? Math.max(...arr) + 1 : 1;
            } else {
                length = obj.filter((i) => i.key === item.key).length;
            }

            let pdfContainer = document.getElementById("pdf-container");
            const ref = refCanvas.current?.style;

            let left = e.clientX - pdfContainer.offsetLeft - 50;

            let _top = (e.clientY + scroll) - 150;

            const pdfHeight = ref?.height?.split('px')[0];
            const top = _top % pdfHeight;

            let field_value = `${item.key?.split(' ')[0] + '_' + 1}`;
            const getGroupId = controls[pageNumber]?.filter((s, i) => s.field_key === 'Radio' || s.field_key === 'Checkbox')
            let groupId = getGroupId?.length ? getGroupId[getGroupId?.length - 1]?.group_id + 1 : 1;
            let recipientColor = recipientList?.filter((s) => s.background === selectColors);
            let initial_color = recipientList?.findIndex(s => s.type === 1 || s.type === 2);

            let background_color = recipientList?.length ? recipientColor?.length ? selectColors : recipientList[initial_color]['background'] : null;
            let backGround = signType === 'Send to others' && recipientList?.length !== 0 ? background_color : '#EDECF7';
            let keyValue = signType === 'Send to others' ? recipientList[selectRecipients]?.id : 'Sign myself';
            let field_type = field_constants.indexOf(item?.key);

            if (item.board_fields !== 'crm_field') {
                if (item?.key === 'Radio' || item?.key === 'Checkbox') {
                    details = [...details, { key: keyValue, field_key: item.key, field_value: `${item.key} ${length}`, value: item.key, is_required: false, group_id: groupId, options: [{ value: field_value, is_selected: false, id: 1, left: left, top: top }], width: 17, height: 17, top: top, left: left, background: backGround, type: field_type + 1, sign_type: signType }]
                } else if (item?.key === 'Dropdown') {
                    details = [...details, { key: keyValue, field_key: item.key, field_value: `${item.key} ${length}`, value: item.key, is_required: true, options: [{ value: 'Dropdown_1', id: 1 }], width: width, height: height, top: top, left: left, background: backGround, type: field_type + 1, fontSize: 14, fontWeight: 'normal', fontStyle: 'normal', fontFamily: 'Inter', sign_type: signType, color: '#000000', is_format_object: false }]
                } else if (item?.key === 'Text') {
                    details = [...details, { key: keyValue, field_key: item.key, field_value: `${item.key} ${length}`, initial_value: item.key, is_required: true, is_read_only: false, value: '', width: width, height: 48, top: top, left: left, background: backGround, type: field_type + 1, fontSize: 14, fontWeight: 'normal', fontStyle: 'normal', fontFamily: 'Inter', sign_type: signType, color: '#000000', is_format_object: false }]
                } else if (item?.key === 'Date') {
                    details = [...details, { key: keyValue, field_key: item.key, field_value: `${item.key} ${length}`, date_format: 1, is_required: true, is_read_only: false, value: signType === 'Send to others' ? item.key : '', width: width, height: height, top: top, left: left, background: backGround, type: field_type + 1, fontSize: 14, fontWeight: 'normal', fontStyle: 'normal', fontFamily: 'Inter', sign_type: signType, color: '#000000', is_format_object: false }]
                } else if (item?.key === 'Signature') {
                    details = [...details, { key: keyValue, field_key: item.key, field_value: `${item.key} ${length}`, is_required: true, is_read_only: false, value: signType === 'Send to others' ? item.key : '', width: width, height: 40, top: top, left: left, background: backGround, type: field_type + 1, fontSize: 14, fontWeight: 'normal', fontStyle: 'normal', fontFamily: 'Inter', sign_type: signType, color: '#000000', is_format_object: false }]
                } else {
                    details = [...details, { key: keyValue, field_key: item.key, field_value: `${item.key} ${length}`, is_required: true, is_read_only: false, value: signType === 'Send to others' ? item.key : '', width: width, height: height, top: top, left: left, background: backGround, type: field_type + 1, fontSize: 14, fontWeight: 'normal', fontStyle: 'normal', fontFamily: 'Inter', sign_type: signType, color: '#000000', is_format_object: false }]
                }
            } else {
                const newWidth = getTextWidth(pulse_detail[item?.index].value, 14) + 20;
                details = [...details, { key: keyValue, field_key: item.key ? item.key : '', field_value: item.key ? `${item.key} ${length}` : '', is_required: false, is_read_only: false, value: pulse_detail[item?.index].value ? pulse_detail[item?.index].value : '', width: pulse_detail[item?.index].value?.length > 15 ? (700 - left) > newWidth ? width + (newWidth + 12) : 700 - left : width, height: height, top: top, left: left, background: backGround, type: 12, fontSize: 14, fontWeight: 'normal', fontStyle: 'normal', fontFamily: 'Inter', sign_type: signType, board_details: 'board_detail', color: '#000000', is_format_object: false }]
            }

            if (!recipientList?.length && !signerCheckBox && conditionCheck) setShowModal(true);
            setPageNumber(pages);
            setControls((pre) => { return { ...pre, [pages]: [...details] } });
            setActiveField({ ...activeField, show: false });
        }
    };

    const getTextWidth = (text, font) => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        context.font = font;
        return context.measureText(text).width;
    };

    const handleScroll = event => {

        const ref = refCanvas.current?.style;
        let scroll_value = Math.floor(event.currentTarget.scrollTop % ref?.height?.split('px')[0]);
        setScroll(scroll_value);
        const number = refDocument.current.scrollTop || 0;
        const divisor = replaceToNumber(ref?.height) || 0;
        const divisionCount = Math.floor(number / divisor);
        setPageInput(divisionCount + 1);
        setPageNumber(divisionCount + 1);
    };

    const dragEnterHandler = (event) => {
        const item = event.dataTransfer.items && event.dataTransfer.items[0];
        if (item?.kind === "string" && item?.type === 'text/plain') {
            event.preventDefault();
            return;
        }
    };

    const handleDragOver = (event) => {
        event.dataTransfer.dropEffect = "move";
        event.preventDefault();
    };

    const textarea = document.getElementById('multiline-text');

    const isScrollBarClicked = (event) => {
        const { offsetWidth, clientWidth, offsetHeight, clientHeight } = textarea;
        const isHorizontalScrollbar = (event?.offsetX > clientWidth) && (offsetWidth > clientWidth);
        const isVerticalScrollbar = (event?.offsetY > clientHeight) && (offsetHeight > clientHeight);
        return isHorizontalScrollbar || isVerticalScrollbar;
    };

    textarea?.addEventListener('mousedown', (event) => {
        if (isScrollBarClicked(event)) {
            event?.stopPropagation();
        }
    });

    textarea?.addEventListener('mouseup', (event) => {
        if (isScrollBarClicked(event)) {
            event?.stopPropagation();
        }
    });

    const handleDrag = (e, index) => {
        // let current = { key: e.target.dataset.value };
        e.dataTransfer.setData("text/plain", JSON.stringify(index));
        e.dataTransfer.effectAllowed = "move";
    };

    const onChangeHandler = (value, index, key, type, field_value) => {
        const page = Object.entries(controls).reduce((acc, [page, items]) => {
            if (items.some(item => item.field_value === field_value && item.type === type)) {
                acc.push(parseInt(page, 10));
            }
            return acc;
        }, []);

        let data = controls[page[0]];
        if (type !== 13 || data[index]?.sign_type === "Send to others") data[index][key] = value;
        else {
            if (/^[+]?[0-9]*$/.test(value)) {
                data[index][key] = value;
            }
        }
        setControls((pre) => { return { ...pre, [page[0]]: [...data] } });

        if (type === 12 && signType === 'Send to others') setActiveField({ ...activeField, initial_value: value, index: index })
        else setActiveField({ ...activeField, value: value, index: index });
    };

    const onSelectHandler = (value, index, key, optionKey) => {
        let list = controls[pageNumber];
        let options = [];
        activeField?.options?.map((item, i) => {
            optionKey === 'RadioButton' ?
                options.push({
                    id: item.id,
                    is_selected: item.id === key ? true : false,
                    value: item.value,
                    top: item?.top,
                    left: item?.left
                })
                :
                options.push({
                    id: item.id,
                    is_selected: item.id === key ? value : item.is_selected,
                    value: item.value,
                    top: item?.top,
                    left: item?.left
                })
        })
        list[index]['options'] = options;
        setControls((pre) => { return { ...pre, [pageNumber]: [...list] } });
        setActiveField({ ...activeField, options: options });
    };

    const selectHandeler = (item, index) => {
        if (item?.field_key === 'Radio' || item?.field_key === 'Checkbox' || item?.field_key === 'Dropdown') {
            if (item?.field_key !== 'Dropdown') {
                setActiveField({ value: item.value, field_value: item.field_value, index: index, show: true, width: item.width, height: item.height, options: item.options, type: item?.type, is_required: item.is_required, key: item?.key });
            } else {
                setActiveField({ value: item.value, field_value: item.field_value, index: index, show: true, width: item.width, height: item.height, options: item.options, type: item?.type, is_required: item.is_required, key: item?.key, fontWeight: item?.fontWeight, fontStyle: item?.fontStyle, fontFamily: item?.fontFamily, color: item?.color });
            }
        } else {
            if (item?.board_details === "board_detail") {
                setActiveField({ value: item.value, field_value: item.field_value, initial_value: item?.initial_value, index: index, show: true, width: item.width, height: item.height, date: item.date, is_required: item.is_required, is_read_only: item.is_read_only, type: item?.type, board_details: item?.board_details, fontWeight: item?.fontWeight, fontStyle: item?.fontStyle, fontFamily: item?.fontFamily, key: item?.key, color: item?.color });
            } else {
                setActiveField({ value: item.value, field_value: item.field_value, initial_value: item?.initial_value, index: index, show: true, width: item.width, height: item.height, fontSize: item?.fontSize, date: item.date, is_required: item.is_required, is_read_only: item.is_read_only, type: item?.type, fontWeight: item?.fontWeight, fontStyle: item?.fontStyle, fontFamily: item?.fontFamily, key: item?.key, color: item?.color });
            }
        };

        if (item?.sign_type === "Send to others") {
            const index = recipientList.findIndex(s => s.id === item?.key);
            selectFieldRecipient(index);
            selectRecipientColor(recipientList[index]?.background);
        }
    };

    const onEditHandler = (value, index, key, optionIndex, optionKey, field_value, type) => {
        const page = Object.entries(controls).reduce((acc, [page, items]) => {
            if (items.some(item => item.field_value === field_value && item.type === type)) {
                acc.push(parseInt(page, 10));
            }
            return acc;
        }, []);

        let data = controls[page[0]];
        const textArea = textAreaRefs?.current[index];

        if (key === 'date_format') {
            if (data[index]?.sign_type === 'Send to others') {
                setActiveField({ ...activeField, [key]: value });
                data[index][key] = value;
                data[index]['height'] = textArea.scrollHeight + 1;
            } else {
                let date = data[index]?.value ? data[index]?.value : new Date();
                const formats = ['DD/MM/YYYY', 'MM/DD/YYYY', 'YYYY/MM/DD', 'DD/MMM/YYYY', 'MMM/DD/YYYY'];
                let format_date = moment(date, preFormat).format(formats[value - 1]);
                data[index].value = format_date;
                data[index].date_format = value;
                data[index]['height'] = textArea.scrollHeight + 1;
                setPreFormat(formats[value - 1]);
                setActiveField({ ...activeField, [key]: value, value: format_date });
            }
        } else if (key === 'options') {
            let list = [...activeField[key]];
            list[optionIndex][optionKey] = value;
            setActiveField({ ...activeField, [key]: list });
            data[index][key][optionIndex][optionKey] = value;
        } else {
            if (key === 'height') data[index]['height'] = Number(value);
            else data[index][key] = value;
            setActiveField({ ...activeField, [key]: value });
        }

        if ((key === 'fontFamily' || key === 'color' || key === 'fontWeight' || key === 'fontStyle' || key === 'fontSize') && !data[index]?.is_format_object && type !== 3 && type !== 4) data[index]['is_format_object'] = true;
        setControls((pre) => { return { ...pre, [page[0]]: [...data] } });
    };

    const handleAddHandler = (key, type, index, item) => {
        let data = [...activeField.options];
        let val = '';
        if (type === 'Radiobutton') {
            val = `Radio_${data.length + 1}`;
        } else if (type === 'Checkbox') {
            val = `Checkbox_${data.length + 1}`;
        } else if (type === 'Dropdown') {
            val = `Dropdown_${data.length + 1}`;
        }

        if (type === 'Dropdown') {
            data.push({ value: val, id: item?.id + 1 });
        } else {
            data.push({ value: val, is_selected: false, id: item?.id + 1, top: item?.top + 25, left: item?.left });
        }

        setActiveField({ ...activeField, [key]: data });
        controls[pageNumber][index][key] = data;
        setControls(controls);
    };

    const handleDeleteHandler = (key, formIndex, index) => {
        let data = activeField.options.filter((s, i) => i !== index);
        setActiveField({ ...activeField, options: data });
        controls[pageNumber][formIndex][key] = data;
        setControls(controls);
    };

    const handleDelete = (index, field_value, type) => {
        const page = Object.entries(controls).reduce((acc, [page, items]) => {
            if (items.some(item => item.field_value === field_value && item.type === type)) {
                acc.push(parseInt(page, 10));
            }
            return acc;
        }, []);

        let data = controls[page[0]]?.filter((k, i) => i !== index);
        setControls((pre) => { return { ...pre, [page[0]]: [...data] } });
    };

    const handlePageNumber = (val) => {
        const ref = refCanvas.current?.style;
        let num = Number(val);
        if (num >= 1) {
            if (numPages >= num) {
                setPageNumber(num);
                const containerHeight = replaceToNumber(ref?.height) * (num - 1)
                refDocument.current.scrollTop = containerHeight + 20;
            }
            setPageInput(num);
        } else {
            setPageInput('');
        }
    };

    const resizeOption = (data, index, left, top,) => {
        return data.map((s, _idx) => _idx === index ? ({ ...s, options: s.options.map((o, idx) => ({ ...o, top: idx === 0 ? top : (top + (25 * idx)), left })), top, left }) : s)
    };

    const handleResizeHandler = (left, top, width, height, index, field_value) => {
        const page = Object.entries(controls).reduce((acc, [page, items]) => {
            if (items.some(item => item.field_value === field_value)) {
                acc.push(parseInt(page, 10));
            }
            return acc;
        }, []);

        let data = [...controls[page[0]]];
        if (data[index]?.field_key === "Radio") data = resizeOption(data, index, left, top,)
        else if (data[index]?.field_key === "Checkbox") data = resizeOption(data, index, left, top,);

        if (left >= 0) {
            data[index]['left'] = left;
        }
        if (top >= 0) {
            data[index]['top'] = top;
        }
        if (width) {
            data[index]['width'] = width;
        }
        if (height && (data[index]?.type === 12 || data[index]?.type === 9)) {
            data[index]['height'] = height;
        }
        setControls((pre) => { return { ...pre, [page[0]]: [...data] } });
    };

    const handleSignerCheckBox = (type) => {
        setSignerCheckBox(type);
        signerCheckBox_(type);
        if (type) setRecipientList([]);
    };

    const handleModalClose = (e) => {
        setShowModal(e);
        closeModal(e);

        if (!recipientList?.length && !signerCheckBox) setControls({});
    };

    const handleRemovefield = (e) => {
        setShowModal(e);
        closeModal(e);
    };

    const handlePageProperties = () => {
        const ref = refCanvas.current?.style;
        setPageProperty({ width: refCanvas?.current?.clientWidth, height: refCanvas?.current?.clientHeight });
        let data = { page_numbers: [-1], page_info: { height: ref?.height, width: ref?.width } };
        pageInfo_([data]);
    };

    const handleSignerDelete = (color) => {
        if (Object.keys(controls)?.length && recipientList?.length) {
            let data = { ...controls };
            let dataObj = {}
            Object.keys(data)?.map((item, i) => {
                dataObj[item] = data[item]?.filter((list) => list?.sign_type === 'Sign myself' || color.indexOf(list.background) !== -1)
            })
            setControls(dataObj);
        }
    };

    const handleChangeField = (value, type) => {
        if (Object.keys(controls)?.length && !recipientList?.length && !type && value[0]?.type === 1) {
            let data = [...controls[pageNumber]];
            data[0]['background'] = '#377dff1a';
            data[0]['key'] = 1;
            setControls((pre) => { return { ...pre, [pageNumber]: [...data] } });
        }

        if (Object.keys(controls)?.length && !recipientList?.length && !type && value[0]?.type !== 1) {
            setControls((pre) => { return { ...pre, [pageNumber]: [] } });
        }

        if (Object.keys(controls)?.length === 1 && type && !recipientList?.length) {
            let data = [...controls[pageNumber]];
            if (data[0]?.type !== 5 && data[0]?.type !== 10) {
                data[0]['key'] = 'Sign myself';
                data[0]['sign_type'] = 'Sign myself';
                data[0]['value'] = "";
                setControls((pre) => { return { ...pre, [pageNumber]: [...data] } });
            } else {
                setControls((pre) => { return { ...pre, [pageNumber]: [] } });
            }
        };
    };

    const handleRecipientChange = (list, index) => {
        let data = [...controls[pageNumber]];
        data[index]['key'] = list?.id;
        data[index]['background'] = list?.background;
        let obj = { ...activeField };
        obj['key'] = list?.id;
        setActiveField(obj);
        setControls((pre) => { return { ...pre, [pageNumber]: [...data] } });
    };

    const handleRemoveSignField = (idx) => {
        let data = controls[pageNumber].filter((k, i) => i !== idx);
        setControls((pre) => { return { ...pre, [pageNumber]: [...data] } });
    };

    const handleSignImageWidth = (value, key, idx) => {
        if (path) {
            let data = controls[pageNumber];
            data[idx][key] = value;
            setControls((pre) => { return { ...pre, [pageNumber]: [...data] } });
        }
    };

    return (
        <div className="pdf-viewer-main">
            {file &&
                <div className='grid-document'>
                    <div className='pdf-previewer'>
                        <PDFPreview
                            formData={controls}
                            signType={signType}
                            pageNumber={(val) => handlePageNumber(val, 'preview')}
                            file={file}
                            selectPageNumber={pageNumber}
                        />
                    </div>
                    <div className='pdf-document-container'>
                        {/* <div className="pdf-top-container">
                            <div className="icon" onClick={() => setScale(scale + 50)}><ZoomInIcon className="icons" /></div>
                            <div className="icon" onClick={() => setScale(scale - 50)}><ZoomOutIcon className="icons" /></div>
                        </div> */}
                        <div className="document-page-count">
                            <div className="pdf-top-container">
                                <input className='document-page-input' value={pageInput} onChange={(e) => handlePageNumber(e.target.value, 'input')} type="text" />
                                <span>/</span>&nbsp;<span>{numPages}</span>
                            </div>
                        </div>
                        <div className='pdf-document' onScroll={handleScroll} ref={refDocument}>
                            <div className="pdf-viewer-document">
                                <div className="scrolling-page" onScroll={() => handleDrop()}>
                                    {file && <Document file={file} options={{ workerSrc: "pdf.worker.js" }} onLoadSuccess={(num) => onDocumentLoadSuccess(num)}>
                                        {Array.apply(null, { length: numPages }).map((val, index) => {
                                            return index + 1;
                                        }).map((pages, i) => {
                                            return (
                                                <div id="pdf-container" onDragEnter={dragEnterHandler} key={i}
                                                    onDrop={(e) => handleDrop(e, false, pages)} onDragOver={(e) => handleDragOver(e)}>
                                                    <Page canvasRef={refCanvas} pageNumber={pages} renderAnnotationLayer={false}
                                                        renderTextLayer={false} className='page-canvas' renderMode='canvas' width={scale?.width ?? 700} height={scale?.height ?? 500}>
                                                        <PDFDragField
                                                            formDetails={recipientList?.length || signerCheckBox ? controls[pages] : []}
                                                            signType={signType}
                                                            handleDrag={(e, index) => handleDrag(e, index)}
                                                            selectHandeler={(item, index) => selectHandeler(item, index)}
                                                            onChangeHandler={(value, index, key, type, field_value) => onChangeHandler(value, index, key, type, field_value)}
                                                            handleResizeHandler={handleResizeHandler}
                                                            currentFormat={preFormat}
                                                            handleRemoveSignField={handleRemoveSignField}
                                                            approvals={approvals}
                                                            getWidth={handleSignImageWidth}
                                                            pageProperty={pageProperty}
                                                            textAreaRefs={textAreaRefs}
                                                            openSign={openSign}
                                                            closeSign={() => setOpenSign(false)}
                                                        />
                                                    </Page>
                                                </div>
                                            )
                                        })}
                                    </Document>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='pdf-fields-page'>
                        <PDFFields
                            activeFields={activeField}
                            getSignType={getSignType}
                            recipientlist={recipientList}
                            handleDelete={(index, field_value, type) => handleDelete(index, field_value, type)}
                            onEditHandler={onEditHandler}
                            handleAddHandler={(key, type, index, item) => handleAddHandler(key, type, index, item)}
                            handleDeleteHandler={(key, formIndex, index) => handleDeleteHandler(key, formIndex, index)}
                            onSelectHandler={(value, index, key, optionKey) => onSelectHandler(value, index, key, optionKey)}
                            showModals={showModal}
                            closeModal={(e) => handleModalClose(e)}
                            handleRemovefield={handleRemovefield}
                            signerCheckBox={(type) => handleSignerCheckBox(type)}
                            handleRecipientHandler={(val) => handleRecipientHandler(val)}
                            handleSignerDelete={(color) => handleSignerDelete(color)}
                            signOrders={signOrder}
                            isOrder={isOrder}
                            getPasscode={getPasscode}
                            hidden_password={password}
                            selectColor={selectColor}
                            changeRecipient={handleRecipientChange}
                            handleChangeField={handleChangeField}
                            getUserId={getUserId}
                            approvals={approvals}
                            user_id={user_id}
                            pulse_id_detail={pulse_detail}
                            handleOpenSignature={() => setOpenSign(true)}
                        />
                    </div>
                </div>}
        </div>
    )
};

export default PDFData;