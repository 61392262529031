import { Box, Button, Card } from '@mui/material'
import React from 'react'
import styles from './Invitation.module.css'

const Invitation = () => {

    const handleSignUp = () => {
        if (process.env.REACT_APP_SIGN_UP === 'https://centilio.com/signup.html') {
            window.open(process.env.REACT_APP_SIGN_UP, '_self');
        } else {
            window.open('https://accountlocal.centilio.com/#/signup', '_self')
        };
    };

    return (
        <Box className={styles.invite_main_contain}>
            <Card className={styles.card_main_contain}>
                <Box className={styles.text_container}>
                    <Box className={styles.header_text}>Thanks for contacting us!</Box>
                    <Box className={styles.body_text}>This is the perfect opportunity to start your free 14-day trial. It's great way to experience the power of CentilioSign for yourself.</Box>
                    <Box className={styles.trial_button_wrapper}>
                        <Button variant='contained' className={styles.trial_button} onClick={() => handleSignUp()}>Start free 14-day trial</Button>
                    </Box>
                    <Box className={styles.credit_text}>No credit card required</Box>
                </Box>
            </Card>
        </Box >
    )
};

export default Invitation;