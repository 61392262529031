import { Box } from '@mui/material'
import React from 'react'
import List from './List'
import styles from "./UsersInfo.module.css";
import AdminCustomTable from '../../component/adminCustomTable/AdminCustomTable';
import { useSelector } from 'react-redux';

const UsersInfo = ({ orgInfo, orgUsersInfo, planDetails, userInfo }) => {
  const tableInfo = useSelector((state) => state?.adminAccountDetails);
  const tableHead = tableInfo.tabledata.tables[2].OrgUsers
  const displayNames = tableHead.map(item => item.display_name);
  const Role_Name = ['Role_Name']
  const tableaHeads = displayNames.concat(Role_Name);

  return (
    <Box>
      <Box className={styles.table_value} >
        {userInfo && <List Name={'User Info'} details={userInfo} />}
        {orgInfo && <List Name={'Org Info'} details={orgInfo} />}
        {planDetails && <List Name={'Plan Info'} details={planDetails} />}
        {orgUsersInfo.length ? <Box sx={{ marginBottom: '1rem' }}>
          <AdminCustomTable
            searchName='User Details'
            table='user'
            keys={tableaHeads}
            head={tableaHeads}
            body={orgUsersInfo}
            tablename='User Info'
          // getCheckedId={setCheckedId}
          />
        </Box>:<Box/>}
      </Box>
    </Box>
  )
}

export default UsersInfo