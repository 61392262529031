import React, { useState } from 'react'
import { Box, Button, FormControl, InputAdornment, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { toggleLoader } from '../store/loader/loaderReducer';
import { ReusableButton, ReusableLabel, ReusableSelect, ReusableTextField } from './Fields/AllFields';
import styles from "./Signup.module.css";

const Signup = ({ userId, setUserId }) => {
    const [signtype, setSigntype] = useState(0);
    const [signUp, setSignUp] = useState({ email: "", password: "" })
    const [error, setError] = useState({ email: true, password: true })
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();

    const TypeOptions = [
        { value: 0, label: 'CREATE' },
        { value: 1, label: 'CONFIRM' },
    ];
    const togglePasswordVisibility = () => {
        setShow(!show);
    }



    const handleCreate = () => {

        if (signUp.email === "" && signUp.password === "") {
            setError({ ...error, email: false, password: false })
        }
        else if (signUp.password.length >= 8) {
            setError({ ...error, password: false })
        }

        if (signUp.password.length >= 8) {

            let data = { emailId: signUp.email, password: signUp.password }
            dispatch(toggleLoader(true));
            axios.post(process.env.REACT_APP_USER_SIGNUP, data, { withCredentials: process.env.REACT_APP_CREDENTIALS })
                .then((res) => {
                    dispatch(toggleLoader(false));
                    setError({ ...error, email: true })
                }).catch((err) => {
                    setError({ ...error, email: false, password: true })
                    dispatch(toggleLoader(false));
                    toast.error(err.response.data);
                })
        }
    }
    
    const handleConfirm = () => {
        let data = { user_id: userId }
        dispatch(toggleLoader(true));
        axios.put(process.env.REACT_APP_USER_SIGNUP, data, { withCredentials: process.env.REACT_APP_CREDENTIALS })
            .then((res) => {
                dispatch(toggleLoader(false));
                toast.success("Success");
            }).catch((err) => {
                dispatch(toggleLoader(false));
                toast.error(err.response.data);
            })
    }

    return (
       
        <Box>
            <Box width={"50%"}>
                <Box className={styles.grid_item}>
                    <Box className={styles.grid_text_label}>
                        <ReusableLabel text="Type" />
                        <ReusableSelect options={TypeOptions} value={signtype} onChange={(e) => setSigntype(e.target.value)} />
                    </Box>
                    {!signtype ?
                        <Box>
                            <Box className={styles.grid_text_label}>
                                <ReusableLabel text="Email Id" />
                                <ReusableTextField value={signUp.email} onChange={(e) => setSignUp({ ...signUp, email: e.target.value })} placeholder='Enter Email Id'
                                    error={!error.email}
                                    helperText={!error.email && "Email id Exist"}
                                />
                            </Box>
                            <Box>
                                <Box className={styles.grid_text_label}>
                                    <ReusableLabel text="Password" />
                                    <ReusableTextField
                                        type={show ? 'text' : 'password'}
                                        value={signUp.password}
                                        onChange={(e) => setSignUp({ ...signUp, password: e.target.value })}
                                        placeholder='Enter Password'
                                        error={!error.password}
                                        helperText={!error.password && "Must be at least 8 Characters"}
                                        showIcon
                                        Icon={show ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                        onIconClick={togglePasswordVisibility}
                                    />
                                </Box>
                            </Box>

                            <Box sx={{ paddingTop: '20px', width: '100%', textAlign: 'end' }}>
                                < ReusableButton variant="contained" onClick={handleCreate} label="Submit" />
                            </Box>
                        </Box>
                        :
                        <Box >
                            <Box className={styles.grid_text_label}>
                                <ReusableLabel text="User Id" />
                                <ReusableTextField
                                    value={userId}
                                    onChange={(e) => setUserId(e.target.value)}
                                    placeholder='Enter User Id'
                                />
                            </Box>
                            <Box sx={{ paddingTop: '20px', width: '100%', textAlign: 'end' }}>
                                < ReusableButton variant="contained" onClick={handleConfirm} label="Submit" />
                            </Box>
                        </Box>}
                </Box>
            </Box>
        </Box>
    )
}

export default Signup