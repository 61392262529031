import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '../../component/loader/loader';
import { document_action } from '../../store/documentDetails/document_action';
import './Upload.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Navigation } from '../../utils/handleNavigation';
import { toggleLoader } from '../../store/loader/loaderReducer';
import { Box, Button, debounce, TextField, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { getChannelConnect, getChannelFiles, getUploadChannel } from '../../Api/UploadChannel';
import moment from 'moment';
import { formatBytes } from '../../utils/commonFunction';
import { integration_action } from '../../store/integrations/integrationAction';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AppHeader from '../../component/appHeader/AppHeader';
import { LimitModal } from '../../component/LimitModal';
import { getInit, getUstat } from '../../Api/UserGetApi';
import { welcome_img_action } from '../../store/welcomeImage/welcomeImgAction';
import { upload_action } from '../../store/uploadDocument/uploadAction';
import RefreshIcon from '@mui/icons-material/Refresh';

const images = [
  { img: 'browse.png', width: 27, height: 27 },
  { img: 'google-drive-icon.png', width: 32, height: 32 },
  { img: 'one-drive.png', width: 27, height: 27 },
  { img: 'dropbox.png', width: 27, height: 27 },
  { img: 'box.png', width: 38, height: 25 }
];

const head = ['Name', 'Size', 'Last opened by me'];
const body = ['name', 'size', 'modified_time'];

const apps_details = [
  { name: 'browse', channel: 0, describe: 'Upload documents directly from browse to here.', img: 'Integrations/browse.png' },
  { name: 'Google Drive', channel: 1, describe: 'Upload documents directly from Google drive to here.', img: 'Integrations/google-drive.png' },
  { name: 'One Drive', channel: 2, describe: 'Upload documents directly from One Drive to here.', img: 'Integrations/one-drive.png' },
  { name: 'Dropbox', channel: 3, describe: 'Upload documents directly from Dropbox to here.', img: 'Integrations/dropbox.png' },
  { name: 'Box', channel: 4, describe: 'Upload documents directly from Box to here.', img: 'Integrations/box.png' }
];

const Upload = () => {
  const dispatch = useDispatch();
  const fileInput = useRef(null);
  const scrollRef = useRef(true);
  const navPath = Navigation();
  const navigate = useNavigate();
  const location = useLocation();
  const sdkPath = location.pathname.split('/')[1];

  const [isProgress, setIsProgress] = useState(false);
  const [activeIdx, setActiveIdx] = useState(0);
  const [files, setFiles] = useState([]);
  const [prevPath, setPrevPath] = useState([]);
  const [selectItem, setSelectItem] = useState(null);
  const [selectIdx, setSelectIdx] = useState(null);
  const [channelDetails, setChannelDetails] = useState([]);
  const [status, setStatus] = useState(null);
  const [cursor, setCursor] = useState(null);
  const [isDragOver, setIsDragOver] = useState(false);
  const [limit, setLimit] = useState(false);
  const [connect, setConnect] = useState(null);
  const [reloadModal, setReloadModal] = useState(false);
  const [login, setLogin] = useState(false);
  const [orgId, setOrgId] = useState(false);

  const org_details = useSelector((state) => state.document.value);
  const integration_info = useSelector((state) => state.integrationDetails.value);
  const crm_param = useSelector((state) => state.crm_param.value);
  const show_integeration = useSelector((state) => state.welcomeImgDetail.value);

  const integration_informationRef = useRef(null);
  integration_informationRef.current = integration_info?.integration_information;
  const source = Number(window?.location?.href?.split('source=')[1]);

  useEffect(() => {
    if (source === 2 || source === 5) fetchUstat(source);
    else setConnect(true);
  }, [source]);

  const fetchUstat = (type) => {
    let param = {};
    dispatch(toggleLoader(true));
    getUstat(param, (res) => {
      if (res?.data) {
        handleInitHandler(type);
      } else {
        dispatch(toggleLoader(false));
        setLogin(true);
      }
    }, (err) => {
      toast.error('Please refresh the page and try again.');
      dispatch(toggleLoader(false))
    });
  };

  const handleInitHandler = (type) => {
    let param = {};
    getInit(param, (res) => {
      dispatch(toggleLoader(false));
      let crm_info = res.data.CRM_Integration_Info;
      let changeType = type === 2 ? 7 : 6;
      const channelTypes = crm_info?.length && crm_info?.filter(item => item?.Channel_Type === 6 || item?.Channel_Type === 7).map(item => item?.Channel_Type);
      if (channelTypes?.length && channelTypes.includes(changeType)) {
        dispatch(welcome_img_action(res?.data));
        let user_info = res?.data?.user_information;
        let data = {
          Email: user_info?.Email_Id,
          FirstName: user_info?.First_Name,
          LastName: user_info?.Last_Name,
          OrgId: res?.data?.default_organizations?.Id,
          Status: user_info?.Status,
          RoleName: user_info?.userRole
        };
        dispatch(upload_action(data));
        setConnect(true);
      } else {
        setConnect(false);
      }
    }, (err) => {
      dispatch(toggleLoader(false));
      if (err?.response?.data === 'org_not_found') {
        setOrgId(true);
        setLogin(true);
      };
    })
  };

  const upload_document = async (e) => {
    const maxSize = 30 * 1024 * 1024;
    if (!(e['type'].includes('png') || e['type'].includes('jpg') || e['type'].includes('jpeg') || e['type'].includes('pdf') || e['type'].includes('vnd.ms-excel') || e['type'].includes('vnd.openxmlformats-officedocument.spreadsheetml.sheet') || e['type'].includes('msword') || e['type'].includes('vnd.openxmlformats-officedocument.wordprocessingml.document') || e['type'].includes('vnd.openxmlformats-officedocument.presentationml.presentation') || e['type'].includes('vnd.ms-powerpoint'))) {
      toast.error('Invalid file format. Please upload a valid file in the appropriate format (e.g. .jpg, .jpeg, .pdf, .png ).')
    } else if (e.size > maxSize) {
      toast.error('The file you are trying to upload is too large. The maximum file size allowed is 10 MB.');
    } else {
      handleUpload(e);
    };
  };

  const handleUpload = (file) => {
    setIsProgress(true);
    let form = {
      file: file,
      product_code: 2,
      permission: 2,
      org_id: org_details?.OrgId,
    };

    const formData = new FormData();

    Object.keys(form).forEach((key) => {
      formData.append(key, form[key]);
    });

    dispatch(document_action(null));
    axios.post(process.env.REACT_APP_DOCUMENT, formData, { withCredentials: process.env.REACT_APP_CREDENTIALS })
      .then((res) => {
        let data = res.data;
        setIsProgress(false);
        dispatch(toggleLoader(true));
        dispatch(document_action({ item: data.response }));
        if (sdkPath === 'sdk' || window?.location?.href?.includes("monday.com")) {
          if (source !== 2 && source !== 5) navigate('/sdkhome', { state: { set_refresh: true } });
          dispatch(toggleLoader(false));
          window.open(`${process.env.REACT_APP_URL}/#/c${org_details?.OrgId}/document/${data.response.Id}?crmparam=${crm_param}`, '_blank');
        } else {
          navPath(`/document/${data.response.Id}`);
        }
      })
      .catch((e) => {
        dispatch(toggleLoader(false));
        let err = !e?.response?.data?.error ? e?.response?.data && JSON.parse(JSON.stringify(e?.response?.data)) : e?.response?.data;
        if (err?.error?.status === 600 || err?.error?.status === 601) {
          setLimit(true);
        } else {
          setIsProgress(false);
          toast.error(e.message);
        }
      });
  };

  const handleActive = (idx) => {
    setChannelDetails(integration_informationRef.current);
    setPrevPath([]);
    setStatus(null);
    setSelectItem(null);
    if (integration_informationRef.current?.length) {
      handleConnect(undefined, undefined, undefined, idx);
      setActiveIdx(idx);
    } else {
      setActiveIdx(idx);
    };
  };

  const handleConnect = (item, is_back, list, idx, scroll, cursors) => {
    let arr = [];
    integration_informationRef.current?.map((s) => {
      if (s.Status !== 2) {
        if (s.Channel_Type === apps_details[idx]?.channel) {
          arr.push(s);
        }
      }
    });

    if (arr?.length || list?.length) {
      let channel = arr?.length ? arr[0] : list[0];
      let path = item ? item : '*';


      let data = { Org_Id: org_details?.OrgId, channel_type: channel?.Channel_Type, channel_id: channel?.Channel_Id, cursor: cursors, path: path };

      dispatch(toggleLoader(true));
      getUploadChannel(data, (res) => {
        dispatch(toggleLoader(false));
        let data = res.data;
        setStatus(data?.success);
        if (is_back) {
          setPrevPath(pre => pre.slice(0, -1));
        } else if (!scroll) {
          setPrevPath(pre => ([...pre, path]));
        };

        if (scroll) {
          let file = [...files];
          data?.files?.map((s, i) => { file.push(s) });
          setFiles(file);
        } else {
          setFiles(data.files);
        }
        if (data?.cursor?.length || data?.cursor === null) {
          setCursor(data?.cursor);
        }
      }, (err) => {
        if (!err.response.data.success) {
          setStatus(false);
          toast.error('Your token has expired. Please reconnect the channel.');
        } else {
          toast.error(err.message);
        }
        dispatch(toggleLoader(false));
      });
    } else {
      setFiles([]);
    }
  };

  const fetchSignMySelf = (val, idx) => {
    let arr = integration_informationRef?.current?.find((s) => {
      if (s.Channel_Type === apps_details[idx]?.channel) {
        return s;
      }
    });

    dispatch(toggleLoader(true));
    if (val?.length) {
      axios.get(process.env.REACT_APP_SEARCH, {
        params: {
          org_id: org_details?.OrgId,
          channel_id: arr?.Channel_Id,
          channel_type: arr?.Channel_Type,
          file_name: val,
        }, withCredentials: process.env.REACT_APP_CREDENTIALS
      })
        .then((res) => {
          setFiles(res.data.files);
          dispatch(toggleLoader(false));
        })
        .catch((err) => {
          toast.error(err.message);
          dispatch(toggleLoader(false))
        });
    } else {
      handleConnect(undefined, undefined, undefined, idx);
    }
  };

  const debouncedSendRequest = debounce(fetchSignMySelf, 800);

  const handleSearch = (val, idx) => {
    const encode = encodeURIComponent(val?.value);
    debouncedSendRequest(encode, val?.activeIdx);
  };

  const handleGetIcons = (type) => {
    let key = null;
    let diff = type?.split(".")?.pop();
    if (diff === 'folder') {
      key = type?.split('.')?.pop();
    } else {
      key = type?.split('/')?.pop();
    }

    switch (key) {
      case 'folder':
        return 'folder.svg';
      case 'pdf':
        return 'pdf.svg';
      case 'doc':
        return 'doc.svg';
      case 'xlsx':
        return 'xlsx.svg';
      default:
        return 'image.svg'
    };
  };

  const handleFolderBack = (length) => {
    let data = prevPath[length];
    setSelectIdx(null);
    if (data === '*') {
      setPrevPath([]);
    }
    handleConnect(prevPath[length - 1], true, undefined, activeIdx);
  };

  const handleUploadFile = (item, idx) => {
    let arr = [];
    integration_informationRef.current?.map((s) => {
      if (s.Channel_Type === apps_details[idx]?.channel) {
        arr.push(s);
      }
    });

    let form = {
      org_id: org_details?.OrgId,
      channel_type: arr[0]?.Channel_Type,
      channel_id: arr[0]?.Channel_Id,
      client_file_id: item?.id,
      file_name: item?.name,
    };

    const headers = {
      'Content-Type': 'application/json'
    }

    const formData = new FormData();

    Object.keys(form).forEach((key) => {
      formData.append(key, form[key]);
    });

    dispatch(toggleLoader(true));
    axios.post(process.env.REACT_APP_UPLOAD, formData, { withCredentials: process.env.REACT_APP_CREDENTIALS, headers: headers })
      .then((res) => {
        let data = res.data;
        setIsProgress(false);
        dispatch(document_action({ item: data.response }));
        if (sdkPath === 'sdk' || window?.location?.ancestorOrigins[0]?.includes("monday.com")) {
          if (source !== 2 && source !== 5) navigate('/sdkhome', { state: { set_refresh: true } });
          window.open(`${process.env.REACT_APP_URL}/#/c${org_details?.OrgId}/document/${data.response.Id}?crmparam=${crm_param}`, '_blank');
        } else {
          navPath(`/document/${data.response.Id}`);
        }
      })
      .catch((e) => {
        dispatch(toggleLoader(false));
        if (e?.response?.data?.error?.status === 600 || e?.response?.data?.error?.status === 601) {
          setLimit(true);
        } else {
          setIsProgress(false);
          toast.error(e.message);
        }
      });
  }

  const handleSelectFiles = () => {
    let key = selectItem?.type?.split(".")?.pop();
    setSelectIdx(null);
    if (key === 'folder') {
      handleConnect(selectItem?.id, undefined, undefined, activeIdx);
    } else {
      handleUploadFile(selectItem, activeIdx);
    }
  };

  const handleConnectDrive = (type) => {
    dispatch(toggleLoader(true));
    let options = { org_id: org_details?.OrgId, channel_type: apps_details[type].channel };
    getChannelConnect(options, (res) => {
      dispatch(toggleLoader(false));
      let data = res.data['response data'];
      if (res.data.success) {
        const childWin = window.open(data, "_blank");
        let userAgent = navigator.userAgent;
        let winClosed = setInterval(function () {
          if (childWin.closed) {
            clearInterval(winClosed);
            handleChannelType(options, type);
          }
          else if (childWin.length === 0 && !(userAgent.indexOf("Firefox") !== -1)) {
            if (childWin.location.href.includes('callback')) {
              childWin.close()
            }
          } else if (childWin.length === 0 && userAgent.indexOf("Firefox") !== -1) {
          }
        }, 250);
      } else {
        toast.error('Your token has expired. Please reconnect the channel.');
      }
    }, (err) => {
      toast.error(err.message);
      dispatch(toggleLoader(false));
    });
  };

  const handleChannelType = (options, type) => {
    dispatch(toggleLoader(true));
    getChannelFiles(options, (res) => {
      dispatch(toggleLoader(false));
      let data = res.data;
      if (data?.client_access_token) {
        let list = { Channel_Type: type, Channel_Id: data.channel_id };
        if (channelDetails?.length) {
          setChannelDetails(pre => [...pre, list]);
        } else {
          setChannelDetails([list]);
        }
        if (channelDetails?.length) {
          let obj = [...channelDetails];
          obj.push(list);
          dispatch(integration_action({ integration_information: obj }));
        } else {
          dispatch(integration_action({ integration_information: [list] }));
        }
        handleConnect(undefined, undefined, [list], activeIdx);
      }

    }, (err) => {
      toast.error(err.message);
      dispatch(toggleLoader(false));
    })
  };

  const handleSelectedItems = (item, index, key) => {
    if (key === 'doubleClick') {
      handleSelectFiles();
    };
    setSelectItem(item);
    setSelectIdx(index);
  };

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && cursor) {
      let path = prevPath[prevPath?.length - 1];
      handleConnect(path, undefined, undefined, activeIdx, 'scroll', cursor);
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };
  const handleDragStop = (e) => {
    e.preventDefault();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);
    const files = Array.from(e.dataTransfer.files);
    upload_document(files[0])
  };

  const handleIntegrationConnect = () => {
    dispatch(toggleLoader(true));
    let options = { org_id: org_details?.OrgId, channel_type: source === 2 ? 7 : 6 };
    getChannelConnect(options, (res) => {
      dispatch(toggleLoader(false));
      let data = res.data.data;
      window.open(data, "_blank");
      setConnect(null);
      setReloadModal(true);
    }, (err) => {
      toast.error(err.message);
      dispatch(toggleLoader(false));
    });
  };

  const handleReload = () => {
    setReloadModal(false);
    fetchUstat(source);
  };

  const handleLogin = () => {
    let url;
    if (process.env.REACT_APP_LOGIN?.split('/')[2] === 'accountlocal.centilio.com') {
      url = source === 2 ? 'https://accountlocal.centilio.com/#/login?source=pipedrive' : 'https://accountlocal.centilio.com/#/login?source=zoho';
    } else {
      url = source === 2 ? 'https://account.centilio.com/#/login?source=pipedrive' : 'https://account.centilio.com/#/login?source=zoho';
    }
    window.open(url, '_blank');
    setLogin(false);
    setTimeout(() => {
      setReloadModal(true);
    }, 0);
  };

  const handleOrganization = () => {
    window.open(process.env.REACT_APP_ACCOUNT, '_blank');
    setLogin(false);
    setTimeout(() => {
      setReloadModal(true);
    }, 0);
  };

  return (
    <div className='upload-sec'>
      {connect ?
        <div className='wrapper'>
          <AppHeader moduleText='My Documents' crmPages={sdkPath === 'sdk' ? 'monday_crm' : ''} />
          {sdkPath === 'sdk' && source !== 2 && source !== 5 && <Box className='upload_back_button'>
            <Button
              onClick={() => navigate(`/sdk/my-document?crmparam=${crm_param}`)}
              variant='contained'
              className='upload_back_button'
              style={{ textTransform: 'unset', fontFamily: 'var(--primary-font-family)' }}
            >
              <ArrowBackIcon
                style={{ width: '15px', height: '15px', marginRight: '5px' }}
              />
              Back
            </Button>
          </Box>}
          <div className={sdkPath !== 'sdk' ? 'upload-doc' : 'sdk-upload-doc'}>
            <div className='up-type'>
              {images.map((item, idx) => (
                <img
                  src={process.env.REACT_APP_IMAGE_URL + `upload/${item?.img}`}
                  key={idx}
                  // || (idx === 1 && !JSON.parse(show_integeration?.show_features)[0]?.features?.GOOGLE_DRIVE && JSON.parse(show_integeration?.show_features)[0]?.features?.hasOwnProperty('GOOGLE_DRIVE')))
                  style={{ filter: idx === activeIdx ? 'none' : 'grayscale(100%)', pointerEvents: idx === 2 && 'none', opacity: idx !== activeIdx && 0.8, cursor: 'pointer' }}
                  onClick={() => handleActive(idx)}
                  width={item?.width}
                  height={item?.height}
                  alt='menu-item'
                />
              ))}
            </div>
            <p className='supported-formats'>
              <span className='formats'>Supported formats: <span className='formats-group'>PDF, Word, JPG, PNG, PPT, Excel</span></span>
            </p>
            {!status || activeIdx === 0 ?
              <div
                onDragEnter={handleDragEnter}
                onDragOver={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                className={isDragOver ? 'upsec-areas' : 'upsec-area'}>
                {activeIdx === 0 ? (
                  <div className='browse-inn-part'>
                    {!isProgress ? (
                      <div className='before-upload'>
                        <img
                          src={process.env.REACT_APP_IMAGE_URL + 'upload/dropbox.svg'}
                          className='box_img'
                          alt='menu-item'
                        />
                        <div className='upload-file-text'>Drag and Drop your file here</div>
                        <span className='or'>or</span>
                        <Button
                          variant='contained'
                          onClick={() => fileInput.current.click()}
                          className='btn upload-sign'
                        >
                          <span>Choose file</span>
                        </Button>
                        <input
                          ref={fileInput}
                          type='file'
                          id='upload-doc'
                          style={{ display: 'none' }}
                          onChange={(e) => upload_document(e.target.files[0])}
                        />
                      </div>
                    ) : (
                      <div className='upload-inprogress'>
                        <div>
                          <Loader />
                        </div>
                        <p className='up-text'>Uploading...</p>
                        <p className='up-hint'>Wait until your file is uploaded.</p>
                      </div>
                    )}
                  </div>)
                  :
                  (
                    <div className='before-upload'>
                      <p className='upload-channel'>
                        Upload from{' '}
                        {activeIdx === 1
                          ? 'Google Drive'
                          : activeIdx === 2
                            ? 'One Drive'
                            : activeIdx === 3
                              ? 'Dropbox'
                              : activeIdx === 4 && 'Box'}{' '}
                      </p>
                      <Button variant='contained' className='btn upload-sign' onClick={() => handleConnectDrive(activeIdx)}>Connect</Button>
                    </div>
                  )}
              </div>
              :
              activeIdx !== 0 && (
                <Box className='upload_container'>
                  <Box className='upload_wrapper'>
                    <Box className='upload_search'>
                      <Box className='search_container'>
                        <Box className='search_img'>
                          <img src={process.env.REACT_APP_IMAGE_URL + 'ToggleSvg/search.svg'} width='12px' height='12px' alt='searchIcon' />
                        </Box>
                        <TextField
                          onChange={(e) => handleSearch({ value: e.target.value, activeIdx })}
                          sx={{
                            minWidth: 220,
                            '& input': { fontSize: '13px', padding: '9px 10px 11px 33px' }, position: 'absolute',
                            "& input:hover": {
                              backgroundColor: "#f6f7fa"
                            }
                          }}
                          className='upload_search_bar'
                          placeholder='Search Request'
                          fullWidth
                          InputProps={{ disableUnderline: true }}
                          type="search"
                          variant='standard'
                        />
                      </Box>
                      <Box className='button_container'>
                        <Button variant='outlined' disabled={prevPath?.length === 1 ? true : false} className='back_button' onClick={() => handleFolderBack(prevPath.length - 1)}>Back</Button>
                        <Button variant='contained' disabled={!selectItem ? true : false} className='select_button' onClick={() => handleSelectFiles()}>Select</Button>
                      </Box>
                    </Box>
                    <Box className='search_scroll' ref={scrollRef} onScroll={(e) => handleScroll(e)}>
                      <Table
                        sx={{
                          width: '100%', [`& .${tableCellClasses.root}`]: {
                            borderBottom: "none",
                          },
                        }}
                        aria-label='simple table'
                        padding='none'
                        stickyHeader
                      >
                        <TableHead style={{ backgroundColor: '#fafafd' }} id='search_head'>
                          <TableRow>
                            <TableCell
                              style={{ background: '#fafafd', fontFamily: 'var(--primary-font-family)', width: 30 }}
                            >
                            </TableCell>
                            {head?.map((item, i) => (
                              <TableCell
                                align='left'
                                key={i}
                                style={{
                                  color: 'var(--table-header-font)', fontWeight: 400, fontSize: '12px', background: '#f8fafd', width: i === 0 ? 'unset' : i === 1 ? 100 : 150, textTransform: 'uppercase',
                                }}
                              >
                                <Box style={{ translate: i === 0 ? '-29px' : 'unset' }}>
                                  {item}
                                </Box>
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        {files?.length > 0 ? (
                          <TableBody style={{
                            translate: '0 5px',
                          }}>
                            {files?.map((item, idx) => {
                              let type = handleGetIcons(item?.type);
                              return (
                                <TableRow
                                  key={idx}
                                  sx={{
                                    '&:hover': { backgroundColor: 'rgba(189,197,209,.2)' },
                                    backgroundColor: selectIdx === idx && 'rgba(189,197,209,.2)',
                                    marginTop: '10px',
                                    height: 35,
                                    borderRadius: '6px'
                                  }}
                                >
                                  <TableCell sx={{ border: 'unset', marginTop: '10px' }} component='th' scope='row'>
                                    <img
                                      src={process.env.REACT_APP_IMAGE_URL + `upload/${type}`}
                                      alt='newFolder'
                                      style={{
                                        translate: '6px 3px',
                                        height: 15,
                                        width: 15
                                      }}
                                    />
                                  </TableCell>
                                  {body.map((key, i) => (
                                    <TableCell
                                      key={i}
                                      ign='left'
                                      onClick={() => handleSelectedItems(item, idx, 'singleClick')}
                                      onDoubleClick={() => handleSelectedItems(item, idx, 'doubleClick')}
                                    >
                                      <Typography className='file-name'>
                                        {key === 'modified_time' ? moment(item[key]).format('lll') : key === 'size' ? formatBytes(item[key]) !== `0${''} B` && formatBytes(item[key]) : item[key]}
                                      </Typography>
                                    </TableCell>
                                  ))}
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        ) :
                          <TableBody></TableBody>
                        }
                      </Table>
                    </Box>
                  </Box>
                </Box>)}
          </div>
        </div>
        :
        connect === false && <Box className='login_page'>
          <Typography className='login_header'>Connect the {`${source === 2 ? 'Pipedrive' : 'Zoho CRM'}`} with Centilio sign</Typography>
          <Button variant="contained" className='login_button' onClick={handleIntegrationConnect}>Connect</Button>
        </Box>}
      {reloadModal && <Box className='login_page'>
        <Box className='refresh_header'>
          <img
            src={process.env.REACT_APP_IMAGE_URL + 'sideBarSvg/Centilio.png'}
            alt='welcome-img'
            style={{ width: '30px', height: '30px' }}
          />
          <Typography className='centilio_sign'>
            Centilio Sign
          </Typography>
        </Box>
        <Box>
          <Box className='reload_text'>Reload the webpage for the latest updates.</Box>
          <Box className='reload_button'>
            <Button variant='contained' className='monday_reload_btn' onClick={handleReload}>
              <RefreshIcon sx={{ fontSize: 20, marginRight: '4px' }} />Refresh</Button>
          </Box>
        </Box>
      </Box>}
      {(login || orgId) && !reloadModal &&
        <Box className='login_page'>
          <Typography className='login_header'>Welcome to Centilio Application</Typography>
          <Button variant="contained" className='login_button' onClick={() => orgId ? handleOrganization() : handleLogin()}>{orgId ? 'Create Organization' : 'Login'}</Button>
        </Box>}
      <LimitModal limit={limit} handleModalClose={() => [setLimit(false), setIsProgress(false)]} />
    </div>
  );
};

export default Upload;