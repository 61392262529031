import React, { useEffect, useState } from 'react';
import { FormControl, Select, MenuItem, Box, InputBase, InputLabel, NativeSelect, Tooltip, tooltipClasses, Button, Typography, InputAdornment, IconButton, FormHelperText, Menu, ListItemText } from '@mui/material';
import { TextField } from '@mui/material';
import styles from './AllFields.module.css';
import { styled } from '@mui/material/styles';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { Padding } from '@mui/icons-material';
import DoneIcon from '@mui/icons-material/Done';
import dayjs from 'dayjs';



const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },


    '& .MuiInputBase-input': {
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        fontSize: 16,
        padding: "10px 12px",
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        fontFamily: [
            'Inter',
        ].join(','),

        '&:focus': {
            // borderColor: theme.palette.primary.main,
            boxShadow: '0 0 8px #ccc', // Change BoxShadow on focus
        },
    },




}));

const a11yProps = (index) => {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}
const blue = {
    200: '#99CCFF',
    300: '#66B2FF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    700: '#0066CC',
};

const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
};

const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    box-sizing: border-box;
    width: 320px;
    font-family: 'Inter';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 5px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      outline: 0;
      border-color: ${blue[400]};
      box-shadow: 0 0 1rem 0 rgba(140,152,164,.25);
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
);


export const ReusableSelect = ({ defaultValue = 1, disabled, onClick, tooltip, options, value, onChange, width, size = 'small', style = {}, ...rest }) => {

    const [isFocused, setIsFocused] = useState(false);
    const [selectedValue, setSelectedValue] = useState(0);


    useEffect(() => {
        setSelectedValue(value ? value : defaultValue);
    }, [value]);

    const handleFocus = () => {
        setIsFocused(true);
    };
    const handleBlur = () => {
        setIsFocused(false);
    };
    const handleOnChange = (e) => {
        setSelectedValue(e.target.value);
        onChange(e)
    }



    return (
        <FormControl size={size} style={style} >
            <Tooltip
                title={tooltip}
                arrow
                placement="top"
                slotProps={{
                    popper: {
                        sx: {
                            [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                            {
                                marginTop: '0px',
                            },
                            [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                            {
                                marginBottom: '7px',
                            },
                            [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
                            {
                                marginLeft: '0px',
                            },
                            [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]:
                            {
                                marginRight: '0px',
                            },
                        },
                    },
                }
                }
            >
                <Select
                    disabled={disabled}
                    defaultValue={defaultValue}
                    className={styles.reusable_select_top}
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    value={value}
                    onChange={(e) => handleOnChange(e)}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    input={<BootstrapInput />}
                    renderValue={(selected) => {
                        const selectedOption = options.find((option) => option.value === selected);
                        return (
                            <Box style={{ display: 'flex', alignItems: 'center', height: '25px', fontFamily: 'Inter', fontSize: '14px' }}>
                                {selectedOption?.label}
                            </Box>
                        );
                    }}


                    sx={{
                        width: { width },
                        minWidth: '150px',
                        "& .MuiInputBase-input": {
                            border: ".0625rem solid rgba(231,234,243,.7)",
                            borderColor: isFocused && "rgba(140,152,164,.25)",
                            boxShadow: isFocused && "0 0 1rem 0 rgba(140,152,164,.25)",
                            fontFamily: 'Inter',
                            borderRadius: "4px",
                            position: 'relative',
                            fontSize: '14px',
                            padding: '0.47rem 0.8rem',
                            color: '#677788',
                        },
                        '&.MuiInputBase-root': {
                            fontFamily: 'Inter',
                            fontSize: "14px",
                        },

                    }}
                    MenuProps={{
                        padding: "10px",
                        PaperProps: {
                            sx: {
                                maxHeight: 'calc(100% - 350px)',

                            },

                        },
                        MenuListProps: {
                            sx: {
                                padding: '5px 0px',

                            },
                        }

                    }}

                >

                    {options.map((option) => (
                        <MenuItem key={option.value}
                            onClick={() => onClick && onClick(option.label)}
                            sx={{
                                fontFamily: 'Inter !importent',
                                fontSize: "14px",
                                '&.Mui-selected': {
                                    fontFamily: 'Inter',
                                    fontSize: "14px",
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    color: '#1e2022', // Change selected text color
                                    backgroundColor: 'white', // Optional: Change selected background
                                },

                                '&.Mui-selected:hover': {
                                    backgroundColor: '#BDC5D14D',
                                    // Optional: Change hover background
                                },

                                color: '#677788',
                                '&:hover': {
                                    color: '#000', // Change color on hover
                                    backgroundColor: '#BDC5D14D', // Add background color on hover (optional)
                                },


                            }}
                            className={styles.reusable_select_items}
                            value={option.value}>
                            <Box sx={{ fontFamily: 'Inter', fontSize: '14px' }} >{option.label}</Box>
                            {selectedValue === option.value && (
                                <img style={{ width: '16px', height: '16px' }} src={process.env.REACT_APP_IMAGE_URL + `BasicFields/tick.svg`} />
                            )}

                        </MenuItem>

                    ))}
                </Select>
            </Tooltip>
        </FormControl>
    );
};

export const ReusableTextField = ({ width, showIcon, onIconClick, Icon = null, error, helperText, disabled, tooltip, value, onChange, onKeyDown, placeholder, type = 'text', variant = 'outlined', style = {}, className = '', id = 'outlined-basic', size = 'medium', ...rest }) => {

    return (

        <FormControl size={size} style={style} >
            <Tooltip
                title={tooltip}
                arrow
                placement="top"
                slotProps={{
                    popper: {
                        sx: {
                            [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                            {
                                marginTop: '0px',
                            },
                            [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                            {
                                marginBottom: '7px',
                            },
                            [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
                            {
                                marginLeft: '0px',
                            },
                            [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]:
                            {
                                marginRight: '0px',
                            },
                        },
                    },
                }}
            >
                <BootstrapInput
                    sx={{
                        width: { width },
                        "& .MuiInputBase-input": {
                            border: !showIcon ? ".0625rem solid rgba(231,234,243,.7)" : null,
                            borderRadius: "4px",
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            position: 'relative',
                            padding: "0.6rem 0.6rem",
                            height: '1.5em', "&:focus-within": {
                                borderColor: "rgba(140,152,164,.25)",
                                boxShadow: "0 0 1rem 0 rgba(140,152,164,.25)",
                            },
                        },
                        "&.MuiInputBase-root": {
                            border: showIcon ? ".0625rem solid rgba(231,234,243,.7)" : null,
                            borderRadius: "4px",
                        },
                    }}
                    disabled={disabled}
                    className={className}
                    style={style}
                    type={type}
                    fullWidth
                    endAdornment={showIcon && Icon ? (
                        <InputAdornment onClick={onIconClick}  >
                            <span style={{ cursor: 'pointer', padding: "10px", display: "flex" }}>
                                {Icon}
                            </span>
                        </InputAdornment>
                    ) : null}
                    placeholder={placeholder}
                    onKeyDown={onKeyDown}
                    onChange={onChange}
                    value={value}
                    variant={variant}
                    error={error}
                    helperText={helperText}
                    size={size}
                    {...rest}
                >

                </BootstrapInput>
                {helperText && <FormHelperText sx={{ color: "red" }}>{helperText}</FormHelperText>}
            </Tooltip>
        </FormControl>
    );
};

export const ReusableButton = ({
    label,
    disabled,
    onClick,
    style,
    variant = 'contained',
    size = "large",
    color = 'primary',
    ...props
}) => {
    return (
        <FormControl size={size} style={style} >
            <Button
                sx={{
                    // height: "39.91px",
                    '&:hover': {
                        boxShadow: '0 4px 11px rgba(55,125,255,.35)'
                    },
                }}
                disabled={disabled}
                style={style}
                variant={variant}
                size={size}
                color={color}
                onClick={onClick}
                {...props}
            >
                {label}
            </Button>
        </FormControl>
    );
};

export const ReusableLabel = ({
    text,
    variant = 'body1',
    color = '#1e2022',
    gutterBottom = false,
    align = 'inherit',
    style,
    width,
    ...props
}) => {
    return (
        <Typography
            className={styles.reusable_label}
            variant={variant}
            color={color}
            gutterBottom={gutterBottom}
            align={align}
            style={style}
            fontSize={"14px"}
            fontFamily={"Inter"}
            width={width}
            {...props}
        >
            {text}
        </Typography>
    );
};

export const ReusableDateTimePicker = ({
    label,
    value,
    onChange,
    inputProps,
    width,
    pickerProps,
}) => {
    const now = dayjs();
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
                sx={{
                    "& .MuiOutlinedInput-root": {
                        fontFamily: 'Inter',
                    },
                    "& .MuiOutlinedInput-root": {
                        width: { width },
                        height: '42px',
                        "&:focus-within": {
                            border: "1px solid #ccc", // Apply the same border color
                            borderRadius: "4px",
                        },
                    },
                    "& .MuiOutlinedInput-input": {
                        padding: '0px 10px',
                        background: 'white',
                    },

                }}
                // minDateTime={minDateTime&&now}
                width={width}
                label={label}
                value={value}
                onChange={onChange}
                renderInput={(params) => <BootstrapInput {...params} {...inputProps} />}
                {...pickerProps}
            />
        </LocalizationProvider>
    );
};
export const ReusableDatePicker = ({
    label,
    value,
    onChange,
    inputProps,
    pickerProps,
}) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                sx={{
                    "& .MuiOutlinedInput-root": {
                        fontFamily: 'Inter',
                    },
                    "& .MuiOutlinedInput-root": {

                        height: '42px',
                        "&:focus-within": {
                            border: "1px solid #ccc", // Apply the same border color
                            borderRadius: "4px",
                        },
                    },
                    "& .MuiOutlinedInput-input": {
                        padding: '0px 10px',
                        background: 'white',
                    },

                }}
                label={label}
                value={value}
                onChange={onChange}
                renderInput={(params) => <BootstrapInput {...params} {...inputProps} />}
                {...pickerProps}
            />
        </LocalizationProvider>
    );
};
export const ReusableTextarea = ({
    placeholder = "Type here...",
    value = "",
    onChange,
    minRows = 2,
    maxRows,
    sx = {},
    className = "",
    ...rest
}) => {


    return (
        <Textarea
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            minRows={minRows}
            maxRows={maxRows}
            sx={{ width: "100%", resize: "none", ...sx }}
            className={className}
            {...rest}
        />
    );
};


