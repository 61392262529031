function rearrangeObject(obj={}, keyList=[], deleteList=[]) {
    let rearrangedObj = {};
    
    keyList.forEach(key => {
        if (obj.hasOwnProperty(key)) {
            rearrangedObj[key] = obj[key];
        }
    });
    
    // Include any remaining keys that weren't in the keyList
    Object.keys(obj).forEach(key => {
        if (!rearrangedObj.hasOwnProperty(key)) {
            rearrangedObj[key] = obj[key];
        }
    });

    deleteList.forEach((list) => {
    delete rearrangedObj[list];
    })
    
    return rearrangedObj;
}

export {rearrangeObject};