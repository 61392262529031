import { AppBar, Box, Button, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Tab, Tabs } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { toggleLoader } from '../store/loader/loaderReducer';
import SwipeableViews from 'react-swipeable-views';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { formatBytes } from '../utils/commonFunction';
import styles from "./AllowFeature.module.css";
import { ReusableButton, ReusableDateTimePicker, ReusableLabel, ReusableSelect, ReusableTextField } from './Fields/AllFields';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Box>{children}</Box>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}
const themesx = createTheme({
    components: {
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    border: "1px solid rgba(231, 234, 243, 0.70)", /* Sets the most specific border (bottom) as default */
                    backgroundColor: "white",
                    borderWidth: "0px 0px 1px 0px",
                    borderColor: "DarkGray",
                    "color": "#000000",
                    '&.Mui-selected': {
                        borderColor: "var(--primary)",
                        borderRadius: '0px',
                        borderWidth: "0px 0px 2px 0px",
                        backgroundColor: "white",
                        "color": "var(--primary)",
                        // "fontWeight":'600',



                    },
                },
            },
        },
    },
});

const SubscriptionOptions = [
    { value: 1, label: 'MONTHLY' },
    { value: 2, label: 'YEARLY' },
];

const PlanAccess = ({ orgId, orgIds, setOrgId }) => {
    const dispatch = useDispatch();
    const planInfo = useSelector((state) => state?.adminAccountDetails);
    const momenttimezones = moment.tz.names();
    var zone_name =  moment.tz.guess();
    var timezone = moment.tz(zone_name).zoneAbbr() 
    const theme = useTheme();
    const [value, setValue] = useState(0);
    const [switchValues, setSwitchValues] = useState({ type: 2, org_id: '', plan_type: 2, end_date: '', name: 'PROFESSIONAL_PLAN', timezone: moment.tz.guess(), subscription_type: 1 });
    const [accessValues, setAccessValues] = useState({
        type: 1, org_id: 0, max_documents: 0, max_storage_limit: 0, contacts_limit: 0, max_templates: 0, max_users: 0,
        envelope_limit: 0, api_request_imit: 0, current_storage_limit: 0
    });

    const planHead = planInfo?.tableinfo
    const planName = planInfo?.values?.plan_names
    const planConstants = planInfo?.values?.plan_constants

    const timezones = momenttimezones.filter(tz => 
        // !tz.startsWith("US/") && // Remove "Etc/" timezones
        !tz.includes("WET")&&
        !tz.includes("Zulu")&& 
        !tz.includes("UTC")&&
        !tz.includes("Universal")&&
        !tz.includes("W-SU") 
      );



  
    const orgIdsOptions = useMemo(() => {
        return orgIds.map((table) => ({
            value: table,
            label: table,
        }));
    }, [orgIds]);

    const PlanNameOptions = useMemo(() => {
        return planName?.map((val) => ({
            value: planConstants[val],
            label: val,
        }));
    }, [planName, planConstants]);


    const TimezonesOptions = useMemo(() => {
        return timezones.map((tz) => ({
            value: tz,
            label: tz,
        }));
    }, [timezones]);




    const keyValSwap = {
        max_documents: 'Documents',
        max_templates: 'Templates',
        contacts_limit: 'Contacts',
        max_users: 'Users',
        api_request_imit: 'API Request Limit',
        envelope_limit: 'Envelope Limit',
        max_storage_limit: 'Storage Limit',
        current_storage_limit: 'Current Storage Limit',
    }

    useEffect(() => {
        handlePlan('Professional')
    }, [planConstants])

    useEffect(() => {
        setSwitchValues({ ...switchValues, org_id: orgId })
    }, [orgId])

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getKeyByValue = (obj, value) =>
        Object.keys(obj).find(key => obj[key] === value);


    const handleValueChange = (e, val) => {
        const key = getKeyByValue(keyValSwap, val)

        setAccessValues(pre => ({ ...pre, [key]: e.target.value }))
    }

    const handleSubmit = (e) => {
        let data;
        if (e === 'access') {
            data = {
                "org_id": Number(accessValues.org_id),
                "max_documents": Number(accessValues.max_documents),
                "max_storage_limit": accessValues.max_storage_limit,
                "contacts_limit": accessValues.contacts_limit,
                "max_templates": accessValues.max_templates,
                "max_users": accessValues.max_users,
                "envelope_limit": accessValues.envelope_limit,
                "api_request_imit": accessValues.api_request_imit,
                "current_storage_limit": Number(accessValues.current_storage_limit),
            }

            for (let key in data) {
                if (data[key] === 0) {
                    delete data[key];
                }
            }
        }
        else if (e === 'switch') {
            data = {
                "org_id": switchValues.org_id,
                "plan_type": switchValues.plan_type,
                "end_date": switchValues.end_date,
                "timezone": switchValues.timezone,
                "subscription_type": switchValues.subscription_type,
                "max_documents": Number(accessValues.max_documents),
                "max_storage_limit": Number(accessValues.max_storage_limit),
                "contacts_limit": accessValues.contacts_limit,
                "max_templates": accessValues.max_templates,
                "max_users": accessValues.max_users,
                "envelope_limit": accessValues.envelope_limit,
                "api_request_imit": accessValues.api_request_imit,
                "current_storage_limit": Number(accessValues.current_storage_limit),
            }
        }
        let withCredentials = {
            withCredentials: process.env.REACT_APP_CREDENTIALS
        }

        dispatch(toggleLoader(true));
        axios.put(process.env.REACT_APP_USER_PLAN, data, withCredentials)
            .then((res) => {
                dispatch(toggleLoader(false));
                toast.success("Success");
            }).catch((err) => {
                dispatch(toggleLoader(false));
                toast.error(err.message);
            })
    }
    const handleTime = (e) => {
        let val = dayjs(e.$d).format("YYYY-MM-DDTHH:mm:ss");
        setSwitchValues({ ...switchValues, end_date: val })
    }

    const handlePlan = (name) => {
        setSwitchValues({ ...switchValues, name: name })
        planHead && Object.keys(planHead).forEach(key => {
            if (key === name) {
                setAccessValues({
                    ...accessValues,
                    type: 1,
                    org_id: 0,
                    max_documents: planHead[key].documents,
                    max_storage_limit: planHead[key].product_overall_storage_limit_bytes,
                    contacts_limit: planHead[key].contacts,
                    max_templates: planHead[key].templates,
                    max_users: planHead[key].users,
                    envelope_limit: planHead[key].envelope_limit,
                    api_request_imit: planHead[key].api_request_limit,
                    current_storage_limit: planHead[key].current_storage_limit_bytes
                })
            }

        })
    }

    return (
      <Box >
            <Box>

                <AppBar sx={{ width: 210, backgroundColor: 'white', color: 'black', borderRadius: '5px', boxShadow: "none", width: "100%", fontFamily: "var( --primary-font-family)" }} position="static">
                    <Box sx={{ display: 'flex', justifyContent: "space-between" }}>
                        <ThemeProvider theme={themesx}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                sx={{
                                    '& .MuiTabs-indicator': { display: 'none' }
                                }}
                            >
                                <Tab label="Switch" {...a11yProps(0)} />
                                <Tab label="Access" {...a11yProps(1)} />
                            </Tabs>
                        </ThemeProvider>
                        <Box sx={{ display: 'flex', padding: "6px 30px", justifyContent: 'end' }} >
                            {/* <ReusableLabel text="Org Id" />
                            <ReusableSelect options={orgIdsOptions} value={orgId} disabled={orgIds.length >= 1 ? false : true}
                                style={{ width: '150px' }}
                                onChange={(e) => setOrgId(e.target.value)} /> */}
                        </Box>
                    </Box>
                </AppBar>

                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                >
                    <Box sx={{ width: "50%" }}>
                        <Box className={styles.grid_item} height={" calc(100vh - 133px)"}>
                            <Box className={styles.grid_text_label}>
                                <ReusableLabel text="Org Id" />
                                <ReusableSelect options={orgIdsOptions} value={orgId} disabled={orgIds.length >= 1 ? false : true}
                                    onChange={(e) => setOrgId(e.target.value)} />
                            </Box>
                            <Box className={styles.grid_text_label}>
                                <ReusableLabel text="Plan Type" />
                                <ReusableSelect options={PlanNameOptions} value={switchValues.plan_type} onChange={(e) => setSwitchValues({ ...switchValues, plan_type: e.target.value })} onClick={handlePlan} />
                            </Box>
                            <Box className={styles.grid_text_label}>
                                <ReusableLabel text="Time Zone" />
                                <ReusableSelect options={TimezonesOptions} value={switchValues.timezone} onChange={(e) => setSwitchValues({ ...switchValues, timezone: e.target.value })} />
                            </Box>
                            <Box className={styles.grid_text_label}>
                                <ReusableLabel text="Subscription Type" />
                                <ReusableSelect options={SubscriptionOptions} value={switchValues.subscription_type} onChange={(e) => setSwitchValues({ ...switchValues, subscription_type: e.target.value })} />
                            </Box>
                            <Box className={styles.grid_text_label}>
                                <ReusableLabel text="End Date" />
                                <ReusableDateTimePicker onChange={handleTime} />
                            </Box>
                            {Object.values(keyValSwap).map((val) => <Box className={styles.grid_text_label}>
                                <ReusableLabel text={val} />

                                {(val === 'Storage Limit' || val === 'Current Storage Limit') ?
                                    <Box sx={{ display: 'grid', gridTemplateColumns: "74% 24%", gap: '5px' }}>
                                        <ReusableTextField value={accessValues[getKeyByValue(keyValSwap, val)]} onChange={(e) => handleValueChange(e, val)} placeholder={val} />
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}> {formatBytes(accessValues[getKeyByValue(keyValSwap, val)])}</Box>  </Box> : <ReusableTextField value={accessValues[getKeyByValue(keyValSwap, val)]} onChange={(e) => handleValueChange(e, val)} placeholder={val} />}
                            </Box>)}
                            <Box sx={{ textAlign: 'end', paddingBottom: "10px" }}>
                                <ReusableButton variant="contained" label={'Submit'} onClick={() => handleSubmit("switch")} />
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ width: "50%" }}>

                        <Box className={styles.grid_item} height={" calc(100vh - 133px)"}>
                        {Object.values(keyValSwap).map((val) => <Box className={styles.grid_text_label}>
                                <ReusableLabel text={val} />
                                <ReusableTextField value={accessValues[getKeyByValue(keyValSwap, val)]} onChange={(e) => handleValueChange(e, val)}placeholder={val} />
                            </Box>
                               )}
                                 {/* <Box sx={{ textAlign: 'end', paddingBottom: "10px" }}>
                                <ReusableButton variant="contained" label={'Submit'}   onClick={() => handleSubmit("access")}/> // this is only show button is removed 
                            </Box> */} 
                            
                        </Box>
                     
                    </Box>
                </SwipeableViews>
            </Box>


        </Box>
    )
}

export default PlanAccess