import { Box, Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import AppHeader from "../component/appHeader/AppHeader";
import styles from './RecentActivity.module.css';
import { getAuditLogs } from "../Api/SendSignApi";
import { useDispatch } from "react-redux";
import { toggleLoader } from "../store/loader/loaderReducer";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';

const action = ['created', 'read', 'updated', 'deleted', 'searched', 'changed', 'uploaded', 'connected', 'disconnected'];
const activity_type = ['Default', 'Signature', 'Google_drive', 'Dropbox', 'Box', 'Pipe_drive', 'Monday', 'Sales_force', 'Hubspot', 'Document'];
const module_list = ['Document', 'Template', 'Sign for others', 'Sign my self', 'Contacts', 'Settings', 'Integrations'];
const contact_key = { first_name: 'First Name', last_name: 'Last Name', email_id: 'Email', mobile_number: 'Mobile', email_ids: 'Email', phone_number: 'Phone Number' };

const RecentActivity = () => {
    const [audit_log, setAudit_log] = useState([]);
    const [user_info, setUser_info] = useState(null);
    const [show, setShow] = useState(false);

    const org_info = useSelector((state) => state?.document?.value);
    const dispatch = useDispatch();

    useEffect(() => {
        let params = { org_id: org_info?.OrgId };
        dispatch(toggleLoader(true));
        getAuditLogs(params, (res) => {
            setShow(true);
            dispatch(toggleLoader(false));
            if (res?.data?.audit_log?.length) {
                setAudit_log(res?.data?.audit_log);
                let user = res?.data?.user_information[0][res?.data?.audit_log[0]?.created_by];
                setUser_info(user);
            }
        }, (err) => {
            setShow(true);
            dispatch(toggleLoader(false));
            toast.error(err.message);
        })
    }, []);

    const handleAuditinfo = (list) => {
        if (list.module === 5) {
            switch (list?.action) {
                case 1:
                    return <Box><span className={styles.name_text}>{list?.info && list?.activity_type === 10 ? list?.info : `${JSON.parse(list?.info)?.first_name}(${JSON.parse(list?.info)?.email_id})`}</span>{list?.activity_type === 10 ? 'contacts has been imported' : `has been ${action[list?.action - 1]}.`}</Box>
                case 3:
                    let data = list?.info ? JSON.parse(list?.info)?.old ? JSON.parse(list?.info)?.old : JSON.parse(list?.info) : {};
                    return <Box sx={{ lineHeight: '23px' }}>
                        {list?.info && JSON.parse(list?.info)?.hasOwnProperty('name') && <Box>In <span className={styles.name_text}>{JSON.parse(list?.info)['name']}</span>,</Box>}
                        {Object.entries(data)?.map(([key, value]) => (
                            <Box key={key}>{key !== 'email_ids' ? <Box><span>{contact_key[key]} was updated from </span><span className={styles.name_text}>{value ? value : 'blank'}</span>to <span className={styles.name_text}>{JSON.parse(list?.info)?.new[key] ? JSON.parse(list?.info)?.new[key] : 'blank'}</span></Box> :
                                <Box><span className={styles.name_text}>{contact_key[key]}</span> was updated</Box>}</Box>))}</Box>
                default:
                    return <Box><span className={styles.name_text}>{list?.info && JSON.parse(list?.info)?.names?.length ? JSON.parse(list?.info)?.names : JSON.parse(list?.info)?.name?.length ? JSON.parse(list?.info)?.name : module_list[list?.module - 1]}</span>{`e-mail has been ${action[list?.action - 1]}.`}</Box>
            }
        } else {
            if (list?.action === 4 && list?.info && JSON.parse(list?.info)?.names?.length >= 5) {
                return <Box><span className={styles.name_text}>{module_list[list?.module - 1]}</span> mass deleted {JSON.parse(list?.info)?.names?.length} items.</Box>
            } else {
                if (list?.action === 3 && (list.module === 1 || list.module === 3 || list.module === 4)) {
                    return <Box><span>Document name was updated from </span><span className={styles.name_text}>{JSON.parse(list?.info)?.old}</span>to <span className={styles.name_text}>{JSON.parse(list?.info)?.new}</span></Box>
                } else {
                    return <Box><span className={styles.name_text}>{list?.info ? JSON.parse(list?.info)?.names?.length ? JSON.parse(list?.info)?.names?.length <= 1 ? JSON.parse(list?.info)?.names : JSON.parse(list?.info)?.names + ' ' : list?.info && JSON.parse(list?.info)?.name : list?.module === 6 ? 'Signature' : module_list[list?.module - 1]}</span>{`has been ${action[list?.action - 1]}.`}</Box>
                }
            }
        }
    };

    return (
        <>
            {show ?
                <Box className={styles.main_wrap}>
                    <Box>
                        <AppHeader moduleText='All Users Activity' />
                        <Card variant="outlined" className={styles.card}>
                            <Box className={styles.audit_logs}>Audit Logs</Box>
                            <CardContent className={styles.card_wrapper}>
                                <TableContainer component={Paper} sx={{ boxShadow: 'none !important' }}>
                                    <Table aria-label='simple table' sx={{ minWidth: '250 !important', borderCollapse: 'separate' }}>
                                        <TableBody>
                                            {audit_log?.length ?
                                                audit_log?.map((list, i) => (
                                                    <TableRow
                                                        key={i}
                                                        sx={{ textWrap: 'nowrap !important', '&:last-child td, &:last-child th': { border: 0 }, '&:hover': { backgroundColor: 'rgba(189,197,209,.2)' } }}
                                                    >
                                                        <TableCell component="th" scope="row" sx={{ border: 'unset' }} style={{ width: '0%' }}>{list?.date}</TableCell>
                                                        <TableCell component="th" scope="row" sx={{ border: 'unset' }} style={{ width: '7.5%' }}>{list?.time}</TableCell>
                                                        <TableCell component="th" scope="row" sx={{ border: 'unset' }} style={{ width: '10%' }}>
                                                            <Tooltip title={user_info?.first_name?.length + user_info?.last_name?.length > 10 ? `${user_info?.first_name} ${user_info?.last_name}` : ''} key={i}>
                                                                <Box className={styles.table_cell_name} style={{ width: 160 }}>{user_info?.first_name} {user_info?.last_name}</Box>
                                                            </Tooltip>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" sx={{ border: 'unset' }} style={{ width: '10%' }}>{module_list[list?.module - 1]}</TableCell>
                                                        <TableCell component="th" scope="row" sx={{ border: 'unset' }}>{(list?.action === 8 || list?.action === 9) ?
                                                            <Box><span className={styles.name_text}>{activity_type[list?.activity_type]}</span>has been {action[list?.action - 1]}</Box> :
                                                            handleAuditinfo(list)}</TableCell>
                                                    </TableRow>
                                                )) : <Box className={styles.no_doc_found}>No audit logs found.</Box>}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardContent>
                        </Card>
                    </Box>
                </Box>
                : <Skeleton sx={{ bgcolor: 'rgba(189,197,209,.2)', margin: '10px' }} animation="wave" variant="rounded" width={'calc(100% - 20px)'} height={'calc(100vh - 80px)'} />}
        </>
    )
};

export default RecentActivity;