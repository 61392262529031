import { Box, Button, FormControl, MenuItem, OutlinedInput, Select } from '@mui/material'
import { DatePicker, DateTimePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import React, { useState } from 'react'
import { toast } from 'react-toastify';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { toggleLoader } from '../store/loader/loaderReducer';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { ReusableButton, ReusableLabel, ReusableSelect, ReusableTextField } from './Fields/AllFields';
import styles from "./Signup.module.css";

const Reschedule = () => {
    const [schedule, setSchedule] = useState({ job_id: "", schedule_time: 0, plan_type: 'Account' })
    const dispatch = useDispatch();

    const ScheduleTimeOptions = [
        { value: 0, label: 'Now' },
    ];
    const PlanTypeOptions = [
        { value: "Account", label: 'Account' },
        { value: "Sign", label: 'Sign' },
    ];

    const handleSchedule = () => {
        let data = { job_id: schedule.job_id, schedule_time: 0 }
        dispatch(toggleLoader(true));
        if (schedule.plan_type === 'Account') {
            axios.post(process.env.REACT_APP_USER_RESCHEDULE_ACCOUNT, data)
                .then((res) => {
                    dispatch(toggleLoader(false));
                }).catch((err) => {
                    dispatch(toggleLoader(false));
                    toast.error(err.response.data);
                })
        }
        else
            axios.post(process.env.REACT_APP_USER_RESCHEDULE_SIGN, data)
                .then((res) => {
                    dispatch(toggleLoader(false));
                }).catch((err) => {
                    dispatch(toggleLoader(false));
                    toast.error(err.response.data);
                })

    }

    return (
        <Box>
            <Box width={"38%"}>
                <Box className={styles.grid_item}>
                    <Box className={styles.grid_text_label}>
                        <ReusableLabel text="job Id" />
                        <ReusableTextField
                            type='Number'
                            value={schedule.job_id}
                            onChange={(e) => setSchedule({ ...schedule, job_id: Number(e.target.value) })}
                            placeholder='Enter job Id'
                        />
                    </Box>
                    <Box className={styles.grid_text_label}>
                        <ReusableLabel text="Schedule Time" />
                        <ReusableSelect options={ScheduleTimeOptions} value={schedule.schedule_time} onChange={(e) => setSchedule({ ...schedule, schedule_time: e.target.value })} />
                    </Box>
                    <Box className={styles.grid_text_label}>
                        <ReusableLabel text="Plan Type" />
                        <ReusableSelect options={PlanTypeOptions} value={schedule.plan_type} onChange={(e) => setSchedule({ ...schedule, plan_type: e.target.value })} />
                    </Box>
                    <Box sx={{ paddingTop: '20px', width: '100%', textAlign: 'end' }}>
                        < ReusableButton variant="contained" onClick={handleSchedule} label="Submit" />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default Reschedule