import { Box, Button } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { toggleLoader } from '../../store/loader/loaderReducer';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import AdminCustomTable from '../../component/adminCustomTable/AdminCustomTable';
import EmailCampaignFilter from '../EmailCampaign/emailCampaignFilter';
import FilterPopover from '../../component/adminCustomTable/FilterPopover';
import HeaderPopover from '../../component/adminCustomTable/HeaderPopover';
import FilterUserId from '../../component/adminCustomTable/filterUserId';
import { useSelector } from 'react-redux';

const StatusValue = [
    { status: 'AWAIT' },
    { status: 'APPROVED' },
    { status: 'SENT' },
    { status: 'FAILED' },
    { status: 'LATER' },
    { status: 'SEND_NOW' },
];

const AllUsers = ({ topvalue, data }) => {
    const [cursor, setCursor] = useState(1);
    const [limit, setLimit] = useState(30);
    const [nextpage, setNextpage] = useState(false);
    const dispatch = useDispatch();
    const [dataDetails, setDataDetails] = useState([]);
    const TableHead = ['Id', 'First_Name', 'Last_Name', 'Mobile_Number', 'Email_Id', 'Country', 'Created_Time', 'Modified_Time', 'Profile_Pictrue', 'Status', 'Time_Zone']
    // const TableHead = ['Id', 'First_Name', 'Last_Name', 'Mobile_Number', 'Email_Id', 'Country', 'Created_Time', 'Modified_Time', 'Profile_Pictrue', 'Status', 'Time_Zone']
    const [selectedHead, setSelectedHead] = useState(['Country', 'Created_Time', 'Email_Id', 'First_Name', 'Id', 'Last_Name', 'Mobile_Number', ' Modified_Time', 'Profile_Pictrue', 'Status', 'Time_Zone']);
    const [filterExpand, setFilterExpand] = useState(false);
    const [filterData, setFilterData] = useState([]);
    const [filterEl, setFilterEl] = useState(null);
    const [headEl, setHeadEl] = useState(null);

    const DefaultFilters = [{ id: 1, columns: '', condition: '', value: [], operator: 'and', type: '', comparison_operator: '' }]
    const [filters, setfilters] = useState(DefaultFilters)
    const { adminAccountDetails, adminDetails, adminDriveDetails, seobotDetails } = useSelector((state) => state)




    // const tables = adminDetails.admintableinfo.tables;
    const UserData = adminAccountDetails.tabledata.tables.find(table => table.User).User




    const getData = () => {
        if (topvalue === 9) {
            let data = {
                filters: filterData,
                limit: limit,
                cursor: cursor,
            }
            for (let key in data) {
                if (data[key] === 0) {
                    delete data[key];
                }
            }
            dispatch(toggleLoader(true));
            setFilterExpand(true)
            axios.post(process.env.REACT_APP_ALL_USERS, data, { withCredentials: process.env.REACT_APP_CREDENTIALS })
                .then((res) => {
                    dispatch(toggleLoader(false));
                    if (res.data.next_cursor) {
                        setNextpage(true)
                    } else {
                        setNextpage(false)
                    }
                    // const data = res.data.data.map((o) => {
                    //     const { Status, ...restData } = o;

                    //     return {
                    //         ...restData,
                    //         // Status: StatusValue[Status].status,
                    //     };
                    // });
                    setDataDetails(res.data.data);
                    dispatch(toggleLoader(false));
                })
                .catch((err) => {
                    dispatch(toggleLoader(false));
                    toast.error(err.message);
                });

        }
    }

    useEffect(() => {
        getData()
    }, [topvalue, cursor, limit, filterData])


    const handleExport = () => {
        let data = {
            limit: limit,
            cursor: cursor
        }
        dispatch(toggleLoader(true));
        axios.post(process.env.REACT_APP_ALL_USERS + '?export=true', data, { responseType: 'blob', withCredentials: process.env.REACT_APP_CREDENTIALS })
            .then((res) => {
                dispatch(toggleLoader(false));
                const href = URL.createObjectURL(res.data);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', "AllUsers.xls");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(href);
                toast.success('Downloaded');
            })
            .catch((err) => {
                dispatch(toggleLoader(false));
                toast.error(err.message);
            });
    }

    const handleCourserData = (type) => {
        if (type === 'increment') {
            setCursor(cursor + 1);

        } else if (type === 'decrement') {
            setCursor(cursor - 1);
        }
    }

    const handleLimitData = (val) => {
        setLimit(val);
        setCursor(1);
    }

    const setCheckedHead = (head) => {
        setSelectedHead(head)
    }
    const handleFilterData = (fdata) => {
        setFilterData(fdata);
        setCursor(1);
    }

    const handleFilterClick = (event) => {
        setFilterEl(event.currentTarget);
    };

    const handleFilterClose = () => {
        setFilterEl(null);
    };

    const headopen = Boolean(headEl);
    const headid = headopen ? 'simple-popover' : undefined;

    const handleHeadClick = (event) => {
        setHeadEl(event.currentTarget);
    };

    const handleHeadClose = () => {
        setHeadEl(null);
    };

    const filteropen = Boolean(filterEl);
    const filterid = filteropen ? 'simple-popover' : undefined;

    const handleHeaderData = (val) => {
        setSelectedHead(val)
    }


    return (
        <Box sx={{
            overflow: 'hidden',
        }}>
            <FilterUserId name='All User' id={filterid} open={filteropen} anchorEl={filterEl} orgtHead={TableHead} onClose={handleFilterClose} filterData={filterData} setfilterdata={handleFilterData} selectedHead={UserData} data={data} filters={filters} setfilters={(e) => setfilters(e)} />
            {/* <FilterPopover id={filterid} open={filteropen} anchorEl={filterEl} onClose={handleFilterClose} orgtHead={TableHead} filterdata={handleFilterData} /> */}
            <HeaderPopover id={headid} open={headopen} anchorEl={headEl} onClose={handleHeadClose} UpdatedHeadData={selectedHead} FixedHead={TableHead} headerdata={handleHeaderData} />

            {/* <div>
                < EmailCampaignFilter filterExpand={filterExpand} orgtHead={TableHead} filterdata={handleFilterData} />
            </div > */}
            <Box sx={{ display: 'flex', gap: "10px", paddingTop: '15px', justifyContent: 'flex-end' }}>
                <Button
                    variant='outlined'
                    component="label"
                    onClick={handleExport}
                >
                    Export Excel
                </Button>
            </Box>
            <AdminCustomTable
                filterleanth={filterData.length}
                clearfilter={() => setFilterData([])}
                cursors={cursor}
                nextpage={nextpage}
                courserData={handleCourserData}
                limitData={handleLimitData}
                // searchName='Datas'
                keys={selectedHead}
                head={selectedHead}
                filterHead={TableHead}
                getCheckedHead={(head) => setCheckedHead(head)}
                body={dataDetails}
                // getCheckedId={(checkedId, e) => handleCheckedId(checkedId, e)}
                allUser="All Users"
                // getCheckedValue={(checkedId, checkedValue, e) => handleCheckedValue(checkedId, checkedValue, e)}
                // filterdata={handleFilterData}
                // SearchDomain={(domain) => setSearchData(domain)}
                openPoper={handleFilterClick}
                openHead={handleHeadClick}
            />
        </Box >
    )
}

export default AllUsers