import { AppBar, Box, Button, FormControl, MenuItem, Select, Skeleton, Tab, Tabs, TextField, Tooltip } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import styles from "./AdminTool.module.css";
import "./admin.css";
import Filter from './Filter';
import axios from 'axios';

import { useDispatch } from 'react-redux';
import { toggleLoader } from '../store/loader/loaderReducer';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { admin_account_details } from '../store/adminDetails/adminAccountAction';
import { getAdminAccountData, getAdminData, getDriveData, getSeoBotData, getadminaccountdata, getadmindata, getdrivedata } from '../Api/AdminDetailsApi';
import { useSelector } from 'react-redux';
import { admin_details } from '../store/adminDetails/adminDetailsAction';
import { admin_drive_details } from '../store/adminDetails/adminDriveAction.js';
import { seo_bot_details } from '../store/adminDetails/seoBotAccountAction.js';

import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';

import AdminCustomTable from '../component/adminCustomTable/AdminCustomTable';
import AllowFeature from './AllowFeature';
import Signup from './Signup';
import Reschedule from './Reschedule';
import PlanAccess from './PlanAccess';
import Banner from './Banner';
import EmailCampaign from '../Admin/EmailCampaign/emailcampaign';

import PromoCode from './PromoCode/PromoCode.js';
import AllUsers from './AllUsers/AllUsers.js';
import InviteUser from './InviteUser/InviteUser.js';
import InfoIcon from '@mui/icons-material/Info';
import UsersInfo from './UserInfo/UsersInfo.js';
import { ReusableButton, ReusableSelect, ReusableTextField } from './Fields/AllFields.js';
import FilterPopover from '../component/adminCustomTable/FilterPopover.js';
import HeaderPopover from '../component/adminCustomTable/HeaderPopover.js';
import FilterUserId from '../component/adminCustomTable/filterUserId.js';
import PaymentInfo from './PaymentInfo/PaymentInfo.js';


const TabPanel = (props) => {
  const { children, value, index, ...other } = props;


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const themesx = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter',
          textTransform: 'none',
          borderBottom: "1px solid rgba(231, 234, 243, 0.70)", /* Sets the most specific border (bottom) as default */
          backgroundColor: "white",
          borderWidth: "0px 0px 1px 0px",
          // borderBottomColor: "DarkGray",
          FontSize: '14px',
          fontWeight: '400',
          color: '#132144',
          '&:hover': {
            color: '#132144',// Darker shade on hover
            // borderColor:'#377dff',
            // borderWidth: '3px', // Change border color
            backgroundColor: "#d5e4ff",
          },

          '&.Mui-selected': {
            FontSize: '14px',
            fontWeight: '600',
            borderWidth: '3px',
            color: '#377dff',
            borderColor: "var(--primary)",
            borderRadius: '0px',
            // borderWidth: "0px 0px 2px 0px",
            backgroundColor: "white",
            // "color": "var(--primary)",
            // "fontWeight":'600',



          },
        },
      },
    },
  },
});

const options = [
  { value: 1, label: 'Email' },
  { value: 2, label: 'User Id' },
  { value: 3, label: 'Org Id' },
];

const AdminTool = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();


  const tableInfo = useSelector((state) => state?.adminAccountDetails);
  const { adminAccountDetails, adminDetails, adminDriveDetails, seobotDetails } = useSelector((state) => state)
  const grand_admin_access = tableInfo?.tabledata?.grand_admin_access
  const loader = useSelector((state) => state?.loader);

  const [type, setType] = useState(1);
  const [id, setId] = useState("");
  const [userId, setUserId] = useState(1);
  const [limit, setLimit] = useState(50);
  const [orgIds, setOrgIds] = useState([]);
  const [orgId, setOrgId] = useState(0);
  const [filterData, setFilterData] = useState([]);


  const [tableData, setTableData] = useState([]);

  const [value, setValue] = useState(0);
  const [topvalue, setTopValue] = useState(0);


  const [nextpage, setNextpage] = useState(false);
  const [skeleton, setSkeleton] = useState(true);
  const [cursor, setCursor] = useState(1);
  const [featureAllowInfo, setFeatureAllowInfo] = useState('');
  const [bannerInfo, setBannerInfo] = useState('');

  const [types, setTypes] = useState('Account');

  const [orgInfo, setOrgInfo] = useState('');
  const [orgUsersInfo, setOrgUsersInfo] = useState([]);
  const [planDetails, setPlanDetails] = useState('');
  const [userInfo, setUserInfo] = useState('');
  const [typevalue, setTypevalue] = useState({ Drive: false, Sign: false, Account: false, SeoBot: false })


  const [filterEl, setFilterEl] = useState(null);
  const [headEl, setHeadEl] = useState(null);
  const [selectedTables, setSelectedTables] = useState(0);
  const [topHeading, setTopHeading] = useState(0);
  const [selectedHead, setSelectedHead] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [fixedTableHeader, setFixedTableHeader] = useState([]);
  const [tableBody, setTableBody] = useState([]);
  const [data, setData] = useState();
  const DefaultFilters = [{ id: 1, columns: '', condition: '', value: [], operator: 'and', type: '', comparison_operator: '' }]
  const [filters, setfilters] = useState(DefaultFilters)



  const orgIdsOptions = orgIds.map((table) => ({
    value: table,
    label: table,
  }));

  const TableDataOptions = tableData.map((table, i) => ({
    value: i,
    label: table,
  }));

  const AccountOptions = [
    { label: 'Account', value: 'Account', enabled: typevalue.Account },
    { label: 'Sign', value: 'Sign', enabled: typevalue.Sign },
    { label: 'Drive', value: 'Drive', enabled: typevalue.Drive },
    { label: 'Seo Bot', value: 'SeoBot', enabled: typevalue.SeoBot }
  ];

  useEffect(() => {

    if (grand_admin_access === undefined) {
      navigate("/");
    }
  }, []);

  const handleFilterClick = (event) => {
    setFilterEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterEl(null);
  };

  const headopen = Boolean(headEl);
  const headid = headopen ? 'simple-popover' : undefined;

  const handleHeadClick = (event) => {
    setHeadEl(event.currentTarget);
  };

  const handleHeadClose = () => {
    setHeadEl(null);
  };

  const filteropen = Boolean(filterEl);
  const filterid = filteropen ? 'simple-popover' : undefined;

  useEffect(() => {
    setSkeleton(true);
    let failureCount = 0;

    const checkAndNavigate = () => {
      if (failureCount === 4) {
        navigate("/");
      }
    };

    const adminDataPromise = new Promise((resolve) => {
      getAdminData(
        (res) => {
          dispatch(admin_details(res));
          handleSetvalues();
          const options = JSON.parse(res.data.feature_allow_info);
          const banner = JSON.parse(res.data.banner_info);
          setBannerInfo(banner);
          setFeatureAllowInfo(options);
          resolve();

        },
        (err) => {
          if (err.response.status === 401) {
            failureCount++;
            checkAndNavigate();
          }
          resolve();
        }
      );
    });

    const driveDataPromise = new Promise((resolve) => {
      getDriveData(
        (res) => {
          dispatch(admin_drive_details(res));
          handleSetvalues();
          if (types === 'Drive') {
            setTypevalue({ ...typevalue, Drive: true });
          }
          resolve();
        },
        (err) => {
          if (err.response.status === 401) {
            failureCount++;
            checkAndNavigate();
          }
          resolve();
        }
      );
    });

    const seoBotDataPromise = new Promise((resolve) => {
      getSeoBotData(
        (res) => {
          dispatch(seo_bot_details(res));
          handleSetvalues();
          if (types === 'SeoBot') {
            setTypevalue({ ...typevalue, SeoBot: true });
          }
          resolve();
        },
        (err) => {
          if (err.response.status === 401) {
            failureCount++;
            checkAndNavigate();
          }
          resolve();
        }
      );
    });

    const adminAccountDataPromise = new Promise((resolve) => {
      getAdminAccountData(
        (res) => {
          dispatch(admin_account_details(res));
          handleSetvalues();
          if (types === 'Account') {
            setTypevalue({ ...typevalue, Account: true });
          }
          resolve();
        },
        (err) => {
          if (err.response.status === 401) {
            failureCount++;
            checkAndNavigate();
          }
          resolve();
        }
      );
    });

    Promise.all([
      adminDataPromise,
      driveDataPromise,
      seoBotDataPromise,
      adminAccountDataPromise,
    ]).then(() => {
      setSkeleton(false);
    });
  }, []);

  useEffect(() => {
    switch (types) {
      case 'Sign':
        const SignTableName = adminDetails?.admintableinfo?.table_names;
        setTableData(SignTableName)
        break;
      case 'Drive':
        const DriveTableName = adminDriveDetails?.driveinfo?.table_names
        setTableData(DriveTableName)
        break;
      case 'Account':
        const AccountTableName = adminAccountDetails?.tabledata?.table_names
        setTableData(AccountTableName)
        break;
      case 'SeoBot':
        const SeoBotTableNames = seobotDetails?.seobotinfo?.table_names
        setTableData(SeoBotTableNames)
        break;
      default:
        setTableData(AccountTableName)
        break;
    }
  }, [types, selectedTables])

  const handleSetvalues = () => {
    switch (types) {
      case 'Sign':
        const SignTableName = adminDetails?.admintableinfo?.table_names;
        setTableData(SignTableName)
        SetTableHeaders(adminDetails?.admintableinfo, SignTableName[selectedTables]);
        setTopHeading(SignTableName[selectedTables])
        break;
      case 'Drive':
        const DriveTableName = adminDriveDetails?.driveinfo?.table_names
        setTableData(DriveTableName)
        SetTableHeaders(adminDriveDetails?.driveinfo, DriveTableName[selectedTables]);
        setTopHeading(DriveTableName[selectedTables])
        break;
      case 'Account':
        const AccountTableName = adminAccountDetails?.tabledata?.table_names
        setTableData(AccountTableName)
        SetTableHeaders(adminAccountDetails?.tabledata, AccountTableName[selectedTables]);
        setTopHeading(AccountTableName[selectedTables])
        break;
      case 'SeoBot':
        const SeoBotTableNames = seobotDetails?.seobotinfo?.table_names
        setTableData(SeoBotTableNames)
        SetTableHeaders(seobotDetails?.seobotinfo, SeoBotTableNames[selectedTables]);
        setTopHeading(SeoBotTableNames[selectedTables])
        break;
      default:
        setTopHeading('Email')
    }
  }

  const SetTableHeaders = (data, tHeading) => {

    data?.tables.forEach(element => {
      if (element[tHeading]) {
        const displayNames = element[tHeading].map(item => item.display_name);
        setTableHeaders(displayNames);
        setFixedTableHeader(displayNames);
        setData(data)
        setSelectedHead(element[tHeading]);
      }
    });
  }


  const handleTables = (e) => {
    setSelectedTables(e)
  };

  useEffect(() => {
    if (tableData?.length > 0) handleTableHead([])
  }, [cursor]);


  useEffect(() => {
    if (filterData?.length > 0) {
      handleTableHead([])
    }
    else if (filterData?.length === 0) {
      orgId && handleTableHead()
    }
  }, [filterData]);

  const handleSetType = (e) => {
    setType(e)
  }

  const submitHandle = (e, type) => {
    if ('Enter' === e.key) {
      if (type !== 1) {
        usersDetails()
      }
      else {
        const isGmail = /^[a-zA-Z0-9._%+-]+@/i.test(id);
        isGmail ? usersDetails() : toast.error('Please enter a valid email.');
      }
    }
  }


  const usersDetails = () => {
    let data = { type: type, id: id, }
    dispatch(toggleLoader(true));

    axios.get(process.env.REACT_APP_USER_USER_INFO, { params: data, withCredentials: process.env.REACT_APP_CREDENTIALS })
      .then((res) => {



        if (res?.data?.org_ids) {
          setUserInfo(res.data.user_info[0])

          setOrgInfo(res.data.org_info[0])
          setOrgUsersInfo(res.data.org_users_info)
          setPlanDetails(res.data.plan_details)
          setOrgIds(res?.data?.org_ids)
          setOrgId(res?.data?.org_ids[0])
          dispatch(toggleLoader(false));
        }
        else {
          toast.error('Invalid Id');
          setOrgUsersInfo(res.data.org_users_info)
          setPlanDetails(res.data.plan_details)
          dispatch(toggleLoader(false));
        }
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(toggleLoader(false))
      })
  };


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const topHandleChange = (event, newValue) => {
    setTopValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const topHandleChangeIndex = (index) => {
    setTopValue(index);
  };


  const handleTableHead = (val) => {
    let data = { table: tableData[selectedTables], limit: Number(limit), cursor: cursor, org_id: orgId, filters: filterData, sort: { name: val?.name ? val?.name : '', order: val?.order ? val?.order : '' } };
    !val && delete data.sort;
    dispatch(toggleLoader(true));

    let Api = ''
    if (types === 'Account') {
      Api = process.env.REACT_APP_QUERY_ACCOUNT
    }
    else if (types === 'Drive') {
      Api = process.env.REACT_APP_QUERY_DRIVE
    }
    else if (types === 'SeoBot') {
      Api = process.env.REACT_APP_QUERY_SEO_BOT
    }
    else Api = process.env.REACT_APP_QUERY

    axios.post(Api, data, { withCredentials: process.env.REACT_APP_CREDENTIALS })
      .then((res) => {

        if (res?.data?.next_cursor) {
          setNextpage(true)
        } else {
          setNextpage(false)
        }

        dispatch(toggleLoader(false));
        const data = res?.data?.data.map((o, i) => {
          const {
            Id,
            ...restData
          } = o;

          return {
            ...restData,
            Id: Id,
          };
        });
        handleSetvalues()
        setTableBody(data);


      }).catch((err) => {
        dispatch(toggleLoader(false));
        toast.error(err.message);
      })
  }

  const handleFilterData = (fdata) => {
    setFilterData(fdata);
    setCursor(1);
  }

  const handleCourserData = (type) => {
    if (type === 'increment') {
      setCursor(cursor + 1);

    } else if (type === 'decrement') {
      setCursor(cursor - 1);
    }
  }

  const handleLimitData = (val) => {
    setLimit(val);
  }
  const handleIdvalue = (e, type) => {
    const value = e.target.value;
    setId(value)

  }

  const handleHeaderData = (val) => {
    setTableHeaders(val)
  }


  return (
    <Box>
      {!skeleton ?
        <Box sx={{ padding: '15px', fontFamily: "inter" }}>
          <AppBar sx={{ width: '100%', backgroundColor: 'white', color: 'black', borderRadius: '5px', boxShadow: "none", backgroundColor: "white", }} position="static">
            <ThemeProvider theme={themesx}>
              <Tabs
                sx={{
                  '& .MuiTabs-indicator': { display: 'none' },
                  fontFamily: 'inter',
                }}

                value={topvalue}
                onChange={topHandleChange}
                indicatorColor="secondary"
                textColor="inherit"
              >
                <Tab label="Table Info" {...a11yProps(0)} />
                <Tab label="Allow Feature" {...a11yProps(1)} />
                <Tab label="Sign Up" {...a11yProps(2)} />
                <Tab label="Reschedule" {...a11yProps(3)} />
                <Tab label="Plan Access" {...a11yProps(4)} />
                <Tab label="Banner" {...a11yProps(5)} />
                <Tab label="Email Campaign" {...a11yProps(6)} />
                <Tab label="Promo Code" {...a11yProps(7)} />
                <Tab label="Invite User" {...a11yProps(8)} />
                {tableInfo?.tabledata?.grand_admin_access && <Tab label="All Users" {...a11yProps(9)} />}
                <Tab label="Payment Info" {...a11yProps(10)} />

              </Tabs>
            </ThemeProvider>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={topvalue}
            onChangeIndex={topHandleChangeIndex}
          >
            <Box>
              {tableData.length > 0 ? <Box className={styles.admin_tool_top}>
                {/* <FilterPopover id={filterid} open={filteropen} anchorEl={filterEl} onClose={handleFilterClose} orgtHead={orgtHead} filterdata={handleFilterData} /> */}

                <FilterUserId name='Data info' id={filterid} open={filteropen} anchorEl={filterEl} onClose={handleFilterClose} orgtHead={fixedTableHeader} filterData={filterData} setfilterdata={handleFilterData} selectedHead={selectedHead} data={data} filters={filters} setfilters={(e) => setfilters(e)} clear={() => setFilterData([])} />
                <HeaderPopover id={headid} open={headopen} anchorEl={headEl} onClose={handleHeadClose} UpdatedHeadData={tableHeaders} FixedHead={fixedTableHeader} headerdata={handleHeaderData} />

                <Box className='table_div'>
                  <Box >
                    <Box display={'flex'} justifyContent={'space-between'}>
                      <Box>
                        <AppBar sx={{ width: 210, backgroundColor: 'white', color: 'black', borderRadius: '5px', boxShadow: "none", width: "100%", fontFamily: "var( --primary-font-family)" }} position="static">
                          <ThemeProvider theme={themesx}>
                            <Tabs
                              value={value}
                              onChange={handleChange}
                              indicatorColor="secondary"
                              textColor="inherit"
                              sx={{
                                '& .MuiTabs-indicator': { display: 'none' }
                              }}
                            >
                              <Tab sx={{ width: '100px' }} label="User info" {...a11yProps(0)} />
                              <Tab label="Data info" {...a11yProps(1)} disabled={orgId ? false : true} />
                            </Tabs>
                          </ThemeProvider>
                        </AppBar>
                      </Box>
                      {value === 0 ?
                        <Box sx={{ display: 'flex', paddingTop: '7px' }}>
                          <Box sx={{ display: 'flex', gap: '29px' }}>
                            <ReusableSelect defaultValue={0} size="small" options={options} value={type} onChange={(e) => handleSetType(e.target.value)} />
                            <ReusableTextField type={type === 1 ? 'email' : 'number'} placeholder={type === 1 ? 'Email' : type === 2 ? 'User ID' : 'Org ID'}
                              onKeyDown={(e) => submitHandle(e, type)} onChange={(e) => handleIdvalue(e, type)} value={id} />
                          </Box>
                          <Box sx={{ display: 'flex', }}>
                            <Box sx={{ display: 'flex', fontSize: "14px", width: '100px', alignItems: 'center', justifyContent: 'center' }} >Org Id</Box>
                            <ReusableSelect defaultValue={0} size="small" options={orgIdsOptions} value={orgId} style={{ width: '150px' }} disabled={orgIds.length >= 1 ? false : true} onChange={(e) => { setId(e.target.value); setOrgId(e.target.value); handleSetType(3) }} />
                          </Box>
                        </Box> :
                        <Box sx={{ display: 'flex', gap: '29px' }}>
                          <Box>
                            <ReusableSelect defaultValue={0} tooltip={'Product'} value={types} onChange={(e) => setTypes(e.target.value)} options={AccountOptions} sx={{ width: '150px' }} />
                          </Box>
                          <Box>
                            <ReusableSelect defaultValue={0} size="small" tooltip={'Tables'} options={TableDataOptions} value={selectedTables} onChange={(e) => handleTables(e.target.value)} />
                          </Box>
                          <Box>
                            <ReusableTextField tooltip={'Limit'} onChange={(e) => setLimit(e.target.value)} value={limit} placeholder='limits' />
                          </Box>
                          <Box >
                            <ReusableButton variant="contained" label={'Submit'} onClick={() => handleTableHead()} />
                          </Box>
                        </Box>

                      }
                    </Box>
                    <SwipeableViews
                      axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                      index={value}
                      onChangeIndex={handleChangeIndex}
                    >

                      <TabPanel value={value} index={0} dir={theme.direction}>
                        <UsersInfo orgInfo={orgInfo} orgUsersInfo={orgUsersInfo} planDetails={planDetails} userInfo={userInfo} />
                      </TabPanel>
                      <TabPanel value={value} index={1} dir={theme.direction}>

                        {tableBody && <AdminCustomTable
                          filterleanth={filterData.length}
                          clearfilter={() => setFilterData([])}
                          tablename={topHeading}
                          cursors={cursor}
                          nextpage={nextpage}
                          courserData={handleCourserData}
                          limitData={handleLimitData}
                          searchName='Datas'
                          keys={tableHeaders}
                          head={tableHeaders}
                          body={tableBody}
                          sortDate={handleTableHead}
                          openPoper={handleFilterClick}
                          openHead={handleHeadClick}
                        />}
                      </TabPanel>
                    </SwipeableViews>
                  </Box>
                </Box>
              </Box> : <Box />}
            </Box >
            <AllowFeature orgId={orgId} orgIds={orgIds} changeOrg={(org) => { setOrgId(org) }} featureAllowInfo={featureAllowInfo} />
            <Signup userId={userId} setUserId={(userid) => setUserId(userid)} />
            <Reschedule />
            <PlanAccess orgId={orgId} orgIds={orgIds} setOrgId={(org) => setOrgId(org)} />
            <Banner userId={userId} bannerInfo={bannerInfo} />
            <EmailCampaign topvalue={topvalue} data={data} />
            <PromoCode />
            <InviteUser changeOrg={(org) => { setOrgId(org) }} orgId={orgId} orgIds={orgIds} />
            <AllUsers topvalue={topvalue} data={data} />
            <PaymentInfo />
          </SwipeableViews >
        </Box >
        : <Box sx={{ display: "flex" }}>
          {[...Array(5)].map((_, index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              width={150}
              height={40}
              sx={{ margin: "25px" }}
            />
          ))}
        </Box >}
    </Box>
  )
}

export default AdminTool
