import { Box, Button, FormControl, MenuItem, Select, TextField } from '@mui/material'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { toggleLoader } from '../../store/loader/loaderReducer';
import { toast } from 'react-toastify';
import { ReusableButton, ReusableLabel, ReusableSelect, ReusableTextField } from '../Fields/AllFields';
import styles from "../Signup.module.css";
import { use } from 'react';
const InviteUser = ({ orgId, orgIds, changeOrg }) => {

    const [inviteUser, setInviteUser] = useState({ email: "", role_id: "", org_id: orgId });
    const [errors, setErrors] = useState({});
    const dispatch = useDispatch();
    const [roleAccess, setRoleAccess] = useState([]);


    const orgIdsOptions = orgIds.map((table) => (
        {
        value: table,
        label: table,
        }
    ));

    const roleAccessValue = roleAccess.map((table) => (
        {
            value: table.role_id,
            label: table.role_name,
        }
    ));

    useEffect(() => {
        setInviteUser({ ...inviteUser, org_id: orgId })
    }, [orgId])


    useEffect(() => {
        if (orgId > 0)
            axios.get(process.env.REACT_APP_ROLE_ID, { withCredentials: process.env.REACT_APP_CREDENTIALS, params: { org_id: orgId } })
                .then((res) => {
                    setRoleAccess(res.data.role_access);
                    setInviteUser({ ...inviteUser, role_id: res.data.role_access[0].role_id,org_id:orgId })
                    dispatch(toggleLoader(false));
                    // toast.success('Success');
                }).catch((err) => {
                    dispatch(toggleLoader(false));
                    let ErrorDetails = err?.response?.data?.details
                    let ResErrorDetails = err?.response?.data?.error?.details
                    ResErrorDetails ? toast.error(err?.response?.data?.error?.details) : ErrorDetails ? toast.error(err?.response?.data?.details) : toast.error(err?.response?.data);

                })
    }, [orgId])

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validateForm(inviteUser);
        if (Object.keys(validationErrors).length === 0) {
            let data = { email_id: inviteUser.email, role_id: inviteUser.role_id, org_id: inviteUser.org_id }

            dispatch(toggleLoader(true));
            axios.post(process.env.REACT_APP_INVITE, data, { withCredentials: process.env.REACT_APP_CREDENTIALS })
                .then((res) => {
                    dispatch(toggleLoader(false));
                    toast.success('Success');
                }).catch((err) => {
                    dispatch(toggleLoader(false));
                    let ErrorDetails = err?.response?.data?.details
                    let ResErrorDetails = err?.response?.data?.error?.details
                    ResErrorDetails ? toast.error(err?.response?.data?.error?.details) : ErrorDetails ? toast.error(err?.response?.data?.details) : toast.error(err?.response?.data);


                })
        } else {
            // Form data is invalid, display validation errors
            setErrors(validationErrors);
        }
    };

    const validateForm = (data) => {
        let errors = {};
        if (!data.email) {
            errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(data.email)) {
            errors.email = 'Email is invalid';
        }
        if (!data.role_id) {
            errors.role_id = 'Role Id is required';
        }
        if (!data.org_id) {
            errors.org_id = 'Org Id is required';
        }
        return errors;
    };

    return (
        // <Box sx={{ display: "flex", justifyContent: ' space-between', flexDirection: 'row-reverse' }}>
        //     <Box sx={{ display: 'flex', gap: '30px', padding: "40px" }}>
        //         <Box sx={{ paddingTop: '5px', width: '100px', textAlign: 'center' }} >Org Id:</Box>
        //         <FormControl size="small" >
        //             <Select
        //                 labelId="demo-simple-select-label"
        //                 id="demo-simple-select"
        //                 value={orgId}
        //                 // onChange={(e) => setOrgId(e.target.value)}
        //                 style={{ height: 35, width: '150px' }}
        //                 disabled={orgIds.length >= 1 ? false : true}
        //             >
        //                 {orgIds?.map((table, i) => (<MenuItem key={i} value={table}>{table}</MenuItem>))}
        //             </Select>
        //         </FormControl>
        //     </Box>
        // <Box sx={{ padding: "40px", width: '575px' }}>
        //     <Box sx={{ display: 'flex', padding: '5px', gap: '20px' }}>
        //         <Box sx={{ paddingTop: '5px', width: '200px', textAlign: 'center' }} >Email:</Box>
        //         <FormControl size="small" sx={{ display: 'flex' }} >
        //             <TextField size="small" type='email'
        //                 value={inviteUser?.email}
        //                 placeholder='Enter Email'
        //                 onChange={(e) => setInviteUser({ ...inviteUser, email: e.target.value })}
        //                 style={{ height: 35, width: '250px' }}
        //                 error={errors?.email}
        //                 helperText={errors?.email && errors?.email}
        //             />
        //         </FormControl>
        //     </Box>
        //     <Box sx={{ display: 'flex', padding: '5px', gap: '20px' }}>
        //         <Box sx={{ paddingTop: '5px', width: '200px', textAlign: 'center' }} >Role Id:</Box>
        //         <FormControl size="small" sx={{ display: 'flex' }} >
        //             <TextField size="small" type='number'
        //                 value={inviteUser?.role_id}
        //                 placeholder='Enter Role Id'
        //                 onChange={(e) => setInviteUser({ ...inviteUser, role_id: e.target.value })}
        //                 style={{ height: 35, width: '250px' }}
        //                 error={errors?.role_id}
        //                 helperText={errors?.role_id && errors?.role_id}
        //             />
        //         </FormControl>
        //     </Box>
        //     <Box sx={{ display: 'flex', padding: '5px', gap: '20px' }}>
        //         <Box sx={{ paddingTop: '5px', width: '200px', textAlign: 'center' }} >Org Id:</Box>
        //         <FormControl size="small" sx={{ display: 'flex' }} >
        //             <TextField size="small" type='number'
        //                 value={inviteUser?.org_id}
        //                 placeholder='Enter Org Id'
        //                 onChange={(e) => setInviteUser({ ...inviteUser, org_id: e.target.value })}
        //                 style={{ height: 35, width: '250px' }}
        //                 error={errors?.org_id}
        //                 helperText={errors?.org_id && errors?.org_id}
        //             />
        //         </FormControl>
        //     </Box>
        //     <Box sx={{ paddingTop: '20px', width: '100%', textAlign: 'end' }}>
        //         <Button variant="contained"
        //             onClick={handleSubmit}
        //         >
        //             Submit</Button>
        //     </Box>

        // </Box>
        // </Box>

        <Box className={styles.grid_container}>
            <Box className={styles.grid_item}>
                <Box className={styles.grid_text_label}>
                    <ReusableLabel text="Email" />
                    <ReusableTextField type='email' value={inviteUser?.email} onChange={(e) => setInviteUser({ ...inviteUser, email: e.target.value })} error={errors?.email} helperText={errors?.email && errors?.email} placeholder='Enter Email Id' />
                </Box>
                {/* <Box className={styles.grid_text_label}>
                    <ReusableLabel text="Role Id" />
                    <ReusableTextField type='number' value={inviteUser?.role_id} onChange={(e) => setInviteUser({ ...inviteUser, role_id: e.target.value })} error={errors?.role_id} helperText={errors?.role_id && errors?.role_id} placeholder='Enter Role Id' />
                </Box> */}
                <Box className={styles.grid_text_label}>
                    <ReusableLabel text="Role Id" />
                    <ReusableSelect defaultValue={0} options={roleAccessValue} value={inviteUser?.role_id} onChange={(e) => setInviteUser({ ...inviteUser, role_id: e.target.value })} />
                </Box>
                <Box className={styles.grid_text_label}>
                    <ReusableLabel text="Org Id" />
                    <ReusableTextField type='number' disabled={orgIds.length >= 1 ? false : true} value={orgId} onChange={(e) => changeOrg(Number(e.target.value))} error={errors?.org_id} helperText={errors?.org_id && errors?.org_id} placeholder='Enter Org Id' />
                </Box>

                <Box sx={{ textAlign: 'end' }}>
                    <ReusableButton variant="contained" label={'Submit'} onClick={handleSubmit} />
                </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'end' }} className={styles.grid_item}>
                {/* <ReusableLabel text="Org Id" />
                    <ReusableSelect options={orgIdsOptions} value={orgId} disabled={orgIds.length >= 1 ? false : true}
                        style={{ width: '150px' }}
                        onChange={(e) => changeOrg(e.target.value)} /> */}
            </Box>
        </Box>

    )
}

export default InviteUser