import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ReusableButton, ReusableSelect, ReusableTextField } from './Fields/AllFields'
import { getAdminAccountData, getAdminData, getDriveData, getSeoBotData } from '../Api/AdminDetailsApi';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { admin_details } from '../store/adminDetails/adminDetailsAction';
import { admin_drive_details } from '../store/adminDetails/adminDriveAction';
import { seo_bot_details } from '../store/adminDetails/seoBotAccountAction';
import { admin_account_details } from '../store/adminDetails/adminAccountAction';
import AdminCustomTable from '../component/adminCustomTable/AdminCustomTable';
import { toggleLoader } from '../store/loader/loaderReducer';
import axios from 'axios';
import { toast } from 'react-toastify';



const Admincontrol = () => {
    const dispatch = useDispatch();
    const [typevalue, setTypevalue] = useState({ Drive: false, Sign: false, Account: false, SeoBot: false });
    const [types, setTypes] = useState('Sign');
    const [tableData, setTableData] = useState([]);
    const [selectedTables, setSelectedTables] = useState(0);
    const [topHeading, setTopHeading] = useState(0);
    const [tableHeaders, setTableHeaders] = useState([]);
    const [featureAllowInfo, setFeatureAllowInfo] = useState('');
    const [bannerInfo, setBannerInfo] = useState('');
    const [tableBody, setTableBody] = useState([]);
    const [cursor, setCursor] = useState(1);
    const [orgId, setOrgId] = useState(1);
    const [filterData, setFilterData] = useState([]);
    const [nextpage, setNextpage] = useState(false);
    const [checkedId, setCheckedId] = useState([]);
    const [filterEl, setFilterEl] = useState(null);
    const [headEl, setHeadEl] = useState(null);

    const [limit, setLimit] = useState(50);

    const { adminAccountDetails, adminDetails, adminDriveDetails, seobotDetails } = useSelector((state) => state)

    // const grand_admin_access = tableInfo?.tabledata?.grand_admin_access

    const AccountOptions = Object.keys(typevalue).map((key) => ({
        label: key,
        value: key,
        enabled: typevalue[key],
    }));

    const TableDataOptions = tableData.map((table, i) => ({
        value: i,
        label: table,
    }));

    useEffect(() => {
        getAdminData(
            (res) => {
                dispatch(admin_details(res));
                handleSetvalues()
                const options = JSON.parse(res.data.feature_allow_info);
                const banner = JSON.parse(res.data.banner_info);
                setBannerInfo(banner);
                setFeatureAllowInfo(options);
            },
            (err) => {
                console.error(err.message);
            }
        );
        getDriveData(
            (res) => {
                dispatch(admin_drive_details(res));
                handleSetvalues()
                if (types === 'Drive') {
                    setTypevalue({ ...typevalue, Drive: true });
                }
            },
            (err) => {
                console.error(err.message);
            }
        );
        getSeoBotData(
            (res) => {
                dispatch(seo_bot_details(res));
                handleSetvalues()
                if (types === 'SeoBot') {
                    setTypevalue({ ...typevalue, SeoBot: true });
                }
            },
            (err) => {
                console.error(err.message);
            }
        );
        getAdminAccountData(
            (res) => {
                dispatch(admin_account_details(res));
                handleSetvalues()
                if (types === 'Account') {
                    setTypevalue({ ...typevalue, Account: true });
                }
            },
            (err) => {
                console.error(err.message);
            }
        );
    }, [])

    useEffect(() => {
        switch (types) {
            case 'Sign':
                const SignTableName = adminDetails?.admintableinfo?.table_names;
                setTableData(SignTableName)
                break;
            case 'Drive':
                const DriveTableName = adminDriveDetails?.driveinfo?.table_names
                setTableData(DriveTableName)
                break;
            case 'Account':
                const AccountTableName = adminAccountDetails?.tabledata?.table_names
                setTableData(AccountTableName)
                break;
            case 'SeoBot':
                const SeoBotTableNames = seobotDetails?.seobotinfo?.table_names
                setTableData(SeoBotTableNames)
                break;
            default:
                setTableData(AccountTableName)
                break;
        }
    }, [types, selectedTables])

    const handleSetvalues = () => {
        switch (types) {
            case 'Sign':
                const SignTableName = adminDetails?.admintableinfo?.table_names;
                setTableData(SignTableName)
                SetTableHeaders(adminDetails?.admintableinfo?.tables, SignTableName[selectedTables]);
                setTopHeading(SignTableName[selectedTables])
                break;
            case 'Drive':
                const DriveTableName = adminDriveDetails?.driveinfo?.table_names
                setTableData(DriveTableName)
                SetTableHeaders(adminDriveDetails?.driveinfo?.tables, DriveTableName[selectedTables]);
                setTopHeading(DriveTableName[selectedTables])
                break;
            case 'Account':
                const AccountTableName = adminAccountDetails?.tabledata?.table_names
                setTableData(AccountTableName)
                SetTableHeaders(adminAccountDetails?.tabledata?.tables, AccountTableName[selectedTables]);
                setTopHeading(AccountTableName[selectedTables])
                break;
            case 'SeoBot':
                const SeoBotTableNames = seobotDetails?.seobotinfo?.table_names
                setTableData(SeoBotTableNames)
                SetTableHeaders(seobotDetails?.seobotinfo?.tables, SeoBotTableNames[selectedTables]);
                setTopHeading(SeoBotTableNames[selectedTables])
                break;
            default:
                setTopHeading('Email')
        }
    }

    const SetTableHeaders = (data, tHeading) => {

        data?.forEach(element => {
            if (element[tHeading]) {
                setTableHeaders(element[tHeading]);
            }
        });
    }


    const handleTables = (e) => {
        setSelectedTables(e)
    };

    const handleFilterClick = (event) => {
        setFilterEl(event.currentTarget);
    };

    const handleFilterClose = () => {
        setFilterEl(null);
    };

    const handleHeadClick = (event) => {

        setHeadEl(event.currentTarget);
    };

    const handleHeadClose = () => {
        setHeadEl(null);
    };

    const handleTableHead = (val) => {
        let data = { table: tableData[selectedTables], limit: Number(limit), cursor: cursor, org_id: orgId, filters: filterData, sort: { name: val?.name ? val?.name : '', order: val?.order ? val?.order : '' } };
        !val && delete data.sort;
        dispatch(toggleLoader(true));

        let Api = ''
        if (types === 'Account') {
            Api = process.env.REACT_APP_QUERY_ACCOUNT
        }
        else if (types === 'Drive') {
            Api = process.env.REACT_APP_QUERY_DRIVE
        }
        else if (types === 'SeoBot') {
            Api = process.env.REACT_APP_QUERY_SEO_BOT
        }
        else Api = process.env.REACT_APP_QUERY

        axios.post(Api, data, { withCredentials: process.env.REACT_APP_CREDENTIALS })
            .then((res) => {

                if (res?.data?.next_cursor) {
                    setNextpage(true)
                } else {
                    setNextpage(false)
                }
                // setOrgTHead(orgHeadData)
                // setaddFilterHeadData(orgHeadData)
                // setFilterExpand(true)

                dispatch(toggleLoader(false));
                const data = res?.data?.data.map((o, i) => {
                    const {
                        Id,
                        ...restData
                    } = o;

                    return {
                        ...restData,
                        Id: Id,
                    };
                });
                handleSetvalues()
                setTableBody(data);


            }).catch((err) => {
                dispatch(toggleLoader(false));
                toast.error(err.message);
            })
    }


    return (
        <Box>
            <Box sx={{ display: 'flex', gap: '29px' }}>
                <Box sx={{ paddingTop: '7px', display: 'flex', gap: '29px' }}>
                    <Box>
                        <ReusableSelect defaultValue={0} tooltip={'Product'} value={types} onChange={(e) => setTypes(e.target.value)} options={AccountOptions} sx={{ width: '150px' }} />
                    </Box>
                </Box>
                <Box>
                    <ReusableSelect defaultValue={0} size="small" tooltip={'Tables'} options={TableDataOptions} value={selectedTables} onChange={(e) => handleTables(e.target.value)} />
                </Box>
                <Box>
                    <ReusableTextField tooltip={'Limit'} onChange={(e) => setLimit(e.target.value)} value={limit} placeholder='limits' />
                </Box>
                <Box >
                    <ReusableButton variant="contained" label={'Submit'} onClick={() => handleTableHead()} />
                </Box>
            </Box>
            <Box>
                {setTableBody && <AdminCustomTable
                    tablename={topHeading}
                    // cursors={cursor}
                    // nextpage={nextpage}
                    // courserData={handleCourserData}
                    // limitData={handleLimitData}
                    searchName='Datas'
                    keys={tableHeaders}
                    body={tableBody}
                    head={tableHeaders}
                    getCheckedId={setCheckedId}
                    sortDate={handleTableHead}
                    openPoper={handleFilterClick}
                    openHead={handleHeadClick}
                // tableBeforeHead={tableBeforeHead}

                />}
            </Box>
        </Box>
    )
}

export default Admincontrol