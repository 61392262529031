import { Avatar, Box, Stack, Tooltip } from '@mui/material';
import React from 'react'
import styles from "./List.module.css";
import { deepOrange } from '@mui/material/colors';
import PersonIcon from '@mui/icons-material/Person';
import { rearrangeObject } from '../../utils/common';


const keyOrder = ["Id", 'First_Name',"Last_Name","Email_Id",'Mobile_Number',"Org_Name","Application_Name","Website","Employee_Count","Description","Country","Time_Zone","Status","Mfa_Enabled","Created_Time","Modified_Time"]
// const hideData = ['First_Name', 'Email_Id'];
const hideData = [];


const List = ({ Name, details }) => {
  
  const orderDatas =  rearrangeObject(details, keyOrder, hideData)

  return (
    <Box className={styles.user_info}>
      <Box className={styles.avatar_name_email}>
        <Box>
          {Name==='User Info'?<Stack direction="row" spacing={2}>
            <Avatar>
              <PersonIcon />
            </Avatar>
          </Stack>:
          <Box  className={styles.table_head_name} sx={{padding:'10px 0px'}}>
          {Name}
          </Box>
          }
        </Box>
        <Box className={styles.table_name}>
          <Box className={styles.first_name}>{details.First_Name}</Box>
          <Box className={styles.email_id}>{details.Email_Id}</Box>
        </Box>
      </Box>
      <div className={styles.grid_container}>
        {Object.keys(orderDatas)
          .filter(key => !hideData.includes(key)).map((key) => (
            <React.Fragment key={key}>
             <div className={styles.grid_item}>{key.replace('_', ' ')}</div>
              <Tooltip title={details[key]+''}><div className={styles.grid_item}>{details[key]+''}</div></Tooltip>
            </React.Fragment>
          ))}
      </div>
    </Box>
  )
}

export default List 