import { Box, FormControlLabel, Popover, Switch } from '@mui/material'
import React from 'react'
import { styled } from '@mui/material/styles';

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: "var(--primary)",
                opacity: 1,
                border: 0,
                ...theme.applyStyles('dark', {
                    backgroundColor: '#2ECA45',
                }),
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: theme.palette.grey[100],
            ...theme.applyStyles('dark', {
                color: theme.palette.grey[600],
            }),
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7,
            ...theme.applyStyles('dark', {
                opacity: 0.3,
            }),
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: '#E9E9EA',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
        ...theme.applyStyles('dark', {
            backgroundColor: '#39393D',
        }),
    },
}));

const HeaderPopover = ({ id, open, anchorEl, onClose, UpdatedHeadData, onClick, FixedHead, headerdata, headerarray }) => {


    const changeArray = (e) => {
        let headerFilter = FixedHead.map(item => ({
            name: item,
            isEnabled: UpdatedHeadData.includes(item).toString(),
        }));

        headerFilter = headerFilter.map(item =>(
            item.name === e ? item.isEnabled === 'true' ? { ...item, isEnabled: 'false' } : { ...item, isEnabled: 'true' } :item
        ));

        let enabledArray = headerFilter.filter(item => item.isEnabled === 'true').map(item => item.name);
        headerdata(enabledArray);

    }

    const handleswitch = (e) => {
        changeArray(e.target.value)
    }

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            // onClick={onClick}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Box sx={{ padding: '1rem 1rem' }}>
                {FixedHead.map((head, i) => (
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '235px' }}>
                        <Box sx={{ display: "flex", alignItems: 'center', color: 'var( --font-color)' }}> {head}</Box>
                        <Box>  <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} checked={UpdatedHeadData.includes(head)} value={head}  onChange={(e) => handleswitch(e)} />} />
                        </Box>
                    </Box>
                ))}
            </Box>
        </Popover>
    )
}

export default HeaderPopover