import { Box, Button, FormControl, MenuItem, OutlinedInput, Select } from '@mui/material'
import axios from 'axios';
import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { toggleLoader } from '../../store/loader/loaderReducer';
import { useDispatch } from 'react-redux';
import { ReusableButton, ReusableLabel, ReusableSelect, ReusableTextField } from '../Fields/AllFields';
import styles from "../AllowFeature.module.css";

const ProductOptions = [
    { value: 3, label: 'SIGN' },
];
const TypeOptions = [
    { value: 1, label: 'PERCENTAGE' },
    { value: 2, label: 'MONTHLY DOLLAR' },
    { value: 3, label: 'YEARLY DOLLAR' },
];
const ActionTypeOptions = [
    { value: 1, label: 'ADD' },
    { value: 2, label: 'DELETE' },
];
const formFields = [
    { label: "Product", type: "select", options: ProductOptions, key: "Product", defaultValue: 3 },
    { label: "Promo Code", type: "text", key: "PromoCode", placeholder: "Enter PromoCode" },
    { label: "Type", type: "select", options: TypeOptions, key: "Type", defaultValue: 1 },
    { label: "Action Type", type: "select", options: ActionTypeOptions, key: "ActionType", defaultValue: 1 },
    { label: "Value", type: "text", key: "Value", placeholder: "Enter Value" },
];

const PromoCode = () => {

    const [promoCodeValue, setPromoCodeValue] = useState({ Product: 3, Type: 1, ActionType: 1, Value: '', PromoCode: '' });
    const dispatch = useDispatch();

    const handlePromoCode = () => {
        let data = {
            product_code: promoCodeValue.Product,
            type: promoCodeValue.Type,
            promo_code: promoCodeValue.PromoCode,
            value: promoCodeValue.Value,
        }
        promoCodeValue.ActionType === 1 ? axios.post(process.env.REACT_APP_PROMOCODE, data, {
            withCredentials: process.env.REACT_APP_CREDENTIALS
        }).then((res) => {
            dispatch(toggleLoader(false));
            toast.success("Success");
        }).catch((err) => {
            dispatch(toggleLoader(false));
            toast.error(err.message);
        }) : axios.delete(process.env.REACT_APP_PROMOCODE, {
            data: data,
            withCredentials: process.env.REACT_APP_CREDENTIALS
        }).then((res) => {
            dispatch(toggleLoader(false));
            toast.success("Deleted");
        }).catch((err) => {
            dispatch(toggleLoader(false));
            toast.error(err.message);
        })
    }

    return (
        <Box width={"50%"}>
        <Box className={styles.grid_item}>
            {formFields.map((field) => (
                <Box className={styles.grid_text_label} key={field.key}>
                    <ReusableLabel text={field.label} />
                    {field.type === "select" ? (
                        <ReusableSelect
                            defaultValue={field.defaultValue}
                            options={field.options}
                            value={promoCodeValue[field.key]}
                            onChange={(e) => setPromoCodeValue({ ...promoCodeValue, [field.key]: e.target.value })}
                        />
                    ) : (
                        <ReusableTextField
                            value={promoCodeValue[field.key]}
                            onChange={(e) => setPromoCodeValue({ ...promoCodeValue, [field.key]: e.target.value })}
                            placeholder={field.placeholder}
                        />
                    )}
                </Box>

            ))}
        </Box>
        <Box sx={{ paddingTop: '20px', width: '100%', textAlign: 'end' }}>
            < ReusableButton variant="contained" onClick={handlePromoCode} label="Submit" />
        </Box>

    </Box>
    )
}

export default PromoCode