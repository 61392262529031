import { Box, Button } from "@mui/material";
import React, { useRef, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import styles from './UploadContact.module.css';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toggleLoader } from "../../store/loader/loaderReducer";

const UploadContact = ({ toggleNewContact }) => {
    const [isProgress, setIsProgress] = useState(false);

    const fileInput = useRef(null);
    const dispatch = useDispatch();
    const org_info = useSelector((state) => state?.document?.value);

    const handleUpload = (e) => {
        const file = e.target.files[0];
        const allowedExtensions = /(\.csv|\.xlsx)$/i;  // !(file['type'].includes('text/csv')) || !(file['type'].includes('xlsx'))
        if (!allowedExtensions.test(file?.name)) {
            toast.error('Invalid file format. Please upload a valid file in the appropriate format (e.g. .text/csv .xlsx)');
            return;
        };

        setIsProgress(true);
        let form = {
            file: file,
            org_id: org_info?.OrgId,
        };

        const formData = new FormData();

        Object.keys(form).forEach((key) => {
            formData.append(key, form[key]);
        });

        dispatch(toggleLoader(true));
        axios.post(process.env.REACT_APP_BULK_CONTACT, formData, { withCredentials: process.env.REACT_APP_CREDENTIALS, responseType: 'blob' })
            .then((res) => {
                setIsProgress(false);
                dispatch(toggleLoader(false));
                toggleNewContact(false);
            })
            .catch((err) => {
                setIsProgress(false);
                dispatch(toggleLoader(false));
                if (err?.response?.data?.error?.message === "file_data_invalid") {
                    toast.error('File data is invalid');
                } else if (err?.response?.data?.error?.message === "file_headres_invalid") {
                    toast.error(err?.response?.data?.error?.details);
                } else if (err?.response?.status === 422) {
                    const contentDisposition = err?.response?.headers?.['content-disposition'];
                    let fileName = 'errorfile.xlsx';
                    if (contentDisposition) {
                        const matches = contentDisposition.match(/filename="(.+?)"/);
                        if (matches && matches[1]) {
                            fileName = matches[1];
                        }
                    }
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(err?.response?.data);
                    link.download = fileName;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                } else {
                    toast.error(err.message);
                };
            });
    };

    const onDownload = () => {
        const url = 'https://us-cdn1.centilio.com/static_files/sign/bulk_contact_import_sample.csv';
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'bulk_contact_import_sample.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <Box className={styles.wrapper}>
            <Box className={styles.header_title}>
                <Box className={styles.title}>
                    <Box className={styles.header_text}>Upload</Box>
                </Box>
            </Box>
            <Box className={styles.btn_samble_box}>
                <img
                    src={process.env.REACT_APP_IMAGE_URL + 'upload/sample_icon.png'}
                    className={styles.sample_icon}
                    alt='menu-item'
                />
                <Box className={styles.text_sample}>Download the sample<span className={styles.file_download} onClick={onDownload}>file</span></Box>
            </Box>
            <Box className={styles.upsec_area}>
                <Box className={styles.browse_inn_part}>
                    <input
                        ref={fileInput}
                        type='file'
                        id='upload-doc'
                        style={{ opacity: 0, position: 'absolute', top: 0, left: 0, width: '100%', height: "100%" }}
                        onChange={(e) => handleUpload(e)}
                    />

                    {!isProgress ? (
                        <Box className={styles.before_upload}>
                            <Box className={styles.supported_formats}>
                                <Box className={styles.formats}>Supported formats: <Box className={styles.formats_group}>Text/csv, xlsx</Box></Box>
                            </Box>
                            <Box>
                                <img
                                    src={process.env.REACT_APP_IMAGE_URL + 'upload/dropbox.svg'}
                                    className={styles.box_img}
                                    alt='menu-item'
                                />
                                <Box className={styles.upload_file_text}>Drag and Drop your file here</Box>
                                <span className={styles.or}>or</span>
                            </Box>
                            <Button
                                variant='contained'
                                onClick={() => fileInput.current.click()}
                                className={styles.btn}
                                onChange={handleUpload}
                            >
                                <span>Choose file</span>
                            </Button>

                        </Box>
                    ) : (
                        <Box className={styles.upload_inprogress}>
                            <Box className={styles.loader_wrap}>
                                <CircularProgress />
                            </Box>
                            <p className={styles.up_text}>Uploading...</p>
                            <p className={styles.up_hint}>Wait until your file is uploaded.</p>
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    )
};

export default UploadContact;