import React, { useEffect, useRef, useState } from "react";
import { Box, Button, IconButton, Modal, Typography, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SignaturePad from 'react-signature-pad-wrapper';
import styles from '../pages/settings/settings.module.css';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import './PDFSign.css';
import { toast } from "react-toastify";
import { toggleLoader } from "../store/loader/loaderReducer";
import { useDispatch } from "react-redux";
import { activeAllowedType, allowedFileTypes } from "../utils/uploadFileFormat";
import axios from "axios";

const colors = ['#000', '#1e87ca', '#ac3235', '#108473'];
const textColor = ['black', 'dodgerblue', 'brown', 'seagreen'];

const PDFSignature = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [type, setType] = useState(null);
    const [color, setColor] = useState('#000');
    const [font, setFont] = useState('Square Peg');
    const [image, setImage] = useState('');
    const [imgType, setImgType] = useState('');
    const [toggleUploadImage, setToggleUploadImage] = useState(false);
    const [text, setText] = useState('');
    const [signType, setSignType] = useState(null);
    const [isDragOver, setIsDragOver] = useState(false);

    const dispatch = useDispatch();
    let signaturePadRef = useRef(null);
    const cropperRef = useRef();
    const signTextRef = useRef();
    const is_mobile = useMediaQuery('(min-width: 600px)');

    useEffect(() => {
        setShowModal(props?.showModal);
        if (props?.getType) {
            setType(props?.getType);
        } else {
            if (props?.signatureOptions?.draw) {
                setType('draw');
            } else if (props?.signatureOptions?.type) {
                setType('type');
            } else if (props?.signatureOptions?.upload) {
                setType('upload');
            } else {
                setType('draw');
            };
        }
    }, [props?.getType, props?.showModal, props?.signatureOptions]);

    useEffect(() => {
        setSignType(props?.signatureOptions);
    }, [props?.signatureOptions]);

    const onClickHandle = (e) => {
        setColor(e);
        signaturePadRef.current.signaturePad.clear();
        signaturePadRef.current.signaturePad.penColor = e;
    };

    const onClearHandle = (e) => {
        signaturePadRef.current.signaturePad.clear();
    };

    const handleOnchange = (e) => {
        setText(e);
        props?.getColor(color);
        props?.getFont(font);
    };

    const uploadFile = (e) => {
        const url = URL.createObjectURL(e);
        const fileExtension = e.name.split(".").at(-1);
        if (!allowedFileTypes.includes(fileExtension)) {
            toast.error('Only image format is accepted');
        } else {
            setImgType(e.name);
            setImage(url);
            setToggleUploadImage(true);
        }
    };

    const blobToBase64 = (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64data = reader.result.split(',')[1];
                resolve(base64data);
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    };

    const handleSignature = () => {
        let img = type === 'upload' ? imgType : 'sign.jpeg';
        if (type === 'type') {
            let textDetails = {
                digest: decodeURIComponent(props?.digest),
                text: text,
                font_size: 60,
                font_color: textColor[colors.indexOf(color)],
                font_family: font === 'Square Peg' ? 'square_peg' : font === 'Caveat' ? 'caveat' : 'bad_script'
            }
            dispatch(toggleLoader(true));
            axios.post(process.env.REACT_APP_TEXT_SIGNATURE, textDetails, { withCredentials: process.env.REACT_APP_CREDENTIALS, responseType: 'blob' })
                .then((res) => {
                    handleModalClose();
                    blobToBase64(res.data).then(base64 => {
                        props?.getImage(`data:image/png;base64,${base64}`);
                    });
                    props?.getText(text);
                    props?.imgType(img);
                    props?.getColor(color);
                    dispatch(toggleLoader(false));
                })
                .catch((err) => {
                    toast.error(err.message);
                    dispatch(toggleLoader(false));
                });
        } else if (type === 'upload') {
            if (typeof cropperRef.current?.cropper !== 'undefined' && toggleUploadImage) {
                handleModalClose();
                let data = cropperRef.current?.cropper.getCroppedCanvas().toDataURL();
                const fileExtension = imgType?.split(".").at(-1);
                if (!activeAllowedType.includes(fileExtension)) {
                    toast.error('Invalid Image format');
                    dispatch(toggleLoader(false));
                } else {
                    props?.imgType(img);
                    props?.getImage(data);
                    setText(null);
                }
            }
            setToggleUploadImage(false);
        } else {
            let empty = signaturePadRef?.current?.signaturePad?.isEmpty();
            let data = signaturePadRef.current.toDataURL('image/png');
            if (!empty) {
                handleModalClose();
                props?.imgType(img);
                props?.getImage(data);
                setText(null);
            }
        }
    };

    const handleCancel = (i) => {
        setToggleUploadImage(false);
    };

    const handleModalClose = () => {
        setShowModal(false);
        props?.signatureModal(false);
    };

    const handleTypeChange = (draw_type) => {
        setType(draw_type);
        props?.type(draw_type);
    };
    const handleDragEnter = (e) => {
        e.preventDefault();
        setIsDragOver(true);
    };
    const handleDragStop = (e) => {
        e.preventDefault();
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setIsDragOver(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragOver(false);
        const files = Array.from(e.dataTransfer.files);
        uploadFile(files[0])
    };

    return (
        <div>
            <Modal
                open={showModal}
                onClose={() => handleModalClose()}
                sx={{ '& .MuiBackdrop-root': { backgroundColor: '#13214440' } }}
                closeAfterTransition
            >
                <div
                    className={
                        styles.new_contact_wrap + ' ' + styles.signature + ' ' + styles.popup
                    }
                    style={{ width: is_mobile ? '500px' : '300px', padding: is_mobile && '45px' }}
                >
                    <div className={styles.pop_close}>
                        <IconButton
                            className={styles.close}
                            onClick={() => handleModalClose()}
                        >
                            <CloseIcon sx={{ "&:hover": { color: "#ed4c78" } }} />
                        </IconButton>
                    </div>
                    <div className={styles.popup_title}>
                        <span>Signature</span>
                    </div>
                    <div className={styles.popup_cont}>
                        <div className={styles.popup_row + ' ' + styles.s_menu_type}>
                            {signType?.draw ?
                                <span
                                    className={styles.draw + ' ' + styles.link}
                                    style={{
                                        color: type === 'draw' ? 'var(--primary)' : 'var(--table-font-color)',
                                        borderBottom: type === 'draw' && '2px solid var(--primary)',
                                    }}
                                    type='button'
                                    onClick={() => handleTypeChange('draw')}
                                >
                                    Draw
                                </span> : null}
                            {signType?.type ?
                                <span
                                    className={styles.type + ' ' + styles.link}
                                    style={{
                                        color: type === 'type' ? 'var(--primary)' : 'var(--table-font-color)',
                                        borderBottom: type === 'type' && '2px solid var(--primary)',
                                    }}
                                    type='button'
                                    onClick={() => handleTypeChange('type')}
                                >
                                    Type
                                </span> : null}
                            {signType?.upload ?
                                <span
                                    className={styles.upload + ' ' + styles.link}
                                    style={{
                                        color: type === 'upload' ? 'var(--primary)' : 'var(--table-font-color)',
                                        borderBottom: type === 'upload' && '2px solid var(--primary)',
                                    }}
                                    type='button'
                                    onClick={() => handleTypeChange('upload')}
                                >
                                    Upload
                                </span> : null}
                        </div>
                    </div>
                    <div onDragEnter={handleDragEnter}
                        onDragOver={handleDragEnter}
                        onDragLeave={handleDragLeave}
                        style={{ backgroundColor: isDragOver ? "#eeecfb" : 'white' }}
                        onDrop={handleDrop} className={styles.sign_area}>
                        {type === 'draw' ? (
                            <div className={styles.draw_area + ' ' + styles.draw}>
                                <div className={styles.top_bar}>
                                    <div className={styles.select_pen}>
                                        <div
                                            className={
                                                color === '#000' ? styles.active_font_color : styles.font_color
                                            }
                                        >
                                            <span
                                                className={styles.black}
                                                style={{ position: 'absolute' }}
                                                onClick={() => onClickHandle('#000')}
                                            ></span>
                                        </div>
                                        <div
                                            className={
                                                color === '#1e87ca' ? styles.active_font_color : styles.font_color
                                            }
                                        >
                                            <span
                                                className={styles.blue}
                                                onClick={() => onClickHandle('#1e87ca')}
                                            ></span>
                                        </div>
                                        <div
                                            className={
                                                color === '#ac3235' ? styles.active_font_color : styles.font_color
                                            }
                                        >
                                            <span
                                                className={styles.red}
                                                onClick={() => onClickHandle('#ac3235')}
                                            ></span>
                                        </div>
                                        <div
                                            className={
                                                color === '#108473' ? styles.active_font_color : styles.font_color
                                            }
                                        >
                                            <span
                                                className={styles.green}
                                                onClick={() => onClickHandle('#108473')}
                                            ></span>
                                        </div>
                                    </div>
                                    <div className={styles.clear_pad} onClick={onClearHandle}>
                                        <span id={styles.clear}>clear</span>
                                    </div>
                                </div>
                                <div className={styles.draw_sign}>
                                    <SignaturePad
                                        height={167}
                                        options={{ minWidth: 1, maxWidth: 3, penColor: ' #000' }}
                                        ref={signaturePadRef}
                                    />
                                </div>
                            </div>
                        ) : type === 'type' ? (
                            <div className={styles.draw_area + ' ' + styles.draw}>
                                <div className={styles.top_bar}>
                                    <div className={styles.select_pen}>
                                        <div
                                            className={
                                                color === '#000' ? styles.active_font_color : styles.font_color
                                            }
                                        >
                                            <span
                                                className={styles.black}
                                                onClick={() => [setColor('#000'), props?.getColor(color)]}
                                            ></span>
                                        </div>
                                        <div
                                            className={
                                                color === '#1e87ca' ? styles.active_font_color : styles.font_color
                                            }
                                        >
                                            <span
                                                className={styles.blue}
                                                onClick={() => [setColor('#1e87ca'), props?.getColor(color)]}
                                            ></span>
                                        </div>
                                        <div
                                            className={
                                                color === '#ac3235' ? styles.active_font_color : styles.font_color
                                            }
                                        >
                                            <span
                                                className={styles.red}
                                                onClick={() => [setColor('#ac3235'), props?.getColor(color)]}
                                            ></span>
                                        </div>
                                        <div
                                            className={
                                                color === '#108473' ? styles.active_font_color : styles.font_color
                                            }
                                        >
                                            <span
                                                className={styles.green}
                                                onClick={() => [setColor('#108473'), props?.getColor(color)]}
                                            ></span>
                                        </div>
                                    </div>
                                    <span className={styles.select_font + ' ' + styles.sl_type}>
                                        <select
                                            style={{ fontFamily: font, marginTop: '10px' }}
                                            onClick={(e) => setFont(e.target.value)}
                                            readOnly
                                            className={styles.select}
                                        >
                                            <option value='Square Peg' style={{ fontFamily: 'Square Peg' }}>
                                                Hello Test
                                            </option>
                                            <option value='Square Peg' style={{ fontFamily: 'Square Peg' }}>
                                                Hello Test
                                            </option>
                                            <option value='Bad Script' style={{ fontFamily: 'Bad Script' }}>
                                                Hello Test
                                            </option>
                                        </select>
                                    </span>
                                </div>
                                <div className={styles.font_sign}>
                                    <input
                                        type='text'
                                        ref={signTextRef}
                                        placeholder='Type here'
                                        value={text}
                                        style={{ fontFamily: font, color: color, marginTop: '10px' }}
                                        onChange={(e) => handleOnchange(e.target.value)}
                                    />

                                    <div id="sign_pad_container" className="sign_pad_wrapper"></div>
                                </div>
                            </div>
                        ) : (
                            <div className={styles.upload_area + ' ' + styles.stylesupload}>
                                <Box className={styles.sign_upload_img}>
                                    <Box className={styles.browse + ' ' + styles.inn_part}>
                                        <Typography className={styles.drop_file_text}>Drop your file here</Typography>
                                        <span className={styles.or}>or</span>
                                        <input
                                            id='uploadss'
                                            accept="image/*"
                                            alt='image'
                                            onChange={(e) => uploadFile(e.target.files[0])}
                                            type='file'
                                            hidden
                                        />
                                        <label
                                            className={styles.btn + ' ' + styles.upload_sign}
                                            htmlFor='uploadss'
                                        >
                                            Choose File
                                        </label>
                                    </Box>
                                </Box>
                            </div>
                        )}
                    </div>
                    <div
                        className={styles.sign_terms}
                        style={{
                            fontFamily: 'var(--primary-font-family)',
                            fontWeight: 400,
                            color: '#193037',
                            fontSize: '14px',
                        }}
                    >
                        By signing this document with an electronic signature, I agree that such
                        signature will be as valid as handwritten signatures and considered
                        originals to the extent allowed by applicable law.
                    </div>

                    <Button variant="outlined" disabled={toggleUploadImage} onClick={() => handleModalClose()} className={styles.cancel}>
                        Cancel
                    </Button>
                    <Button variant="contained" disabled={toggleUploadImage} onClick={handleSignature} className={styles.submit}>
                        Submit
                    </Button>
                </div>
            </Modal>
            <Modal
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '100%',
                    borderRadius: '10px',
                    padding: '30px',
                    overflow: 'hidden',
                    maxWidth: '340px'
                }}
                open={toggleUploadImage}
                onClose={() => setToggleUploadImage(false)}
                closeAfterTransition
            >
                <Typography style={{
                    position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
                    width: '100%', height: '100%', padding: '30px', overflow: 'hidden', backgroundColor: '#d4e9ff'
                }}>
                    <Cropper
                        ref={cropperRef}
                        zoomTo={0}
                        initialAspectRatio={16 / 9}
                        // crop={true}
                        style={{ background: 'transparent', height: '80%', width: "100%" }}
                        src={image}
                        viewMode={1}
                        minCropBoxHeight={10}
                        minCropBoxWidth={10}
                        background={true}
                        responsive={true}
                        autoCropArea={1}
                        checkOrientation={false}
                        guides={true}
                    />

                    <Typography style={{ display: 'flex', gap: '40px', justifyContent: 'space-around', marginTop: '30px' }}>
                        <Button variant='contained' className={styles.submit} onClick={handleSignature}>Save</Button>
                        <Button variant='contained' onClick={() => handleCancel()} className={styles.cropper_cancel}>
                            Cancel
                        </Button>
                    </Typography>
                </Typography>
            </Modal>
        </div>
    )
};

export default PDFSignature;