import { Box} from '@mui/material'
import React, { useState } from 'react'
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { toggleLoader } from '../store/loader/loaderReducer';
import { toast } from 'react-toastify';
import { ReusableButton, ReusableLabel, ReusableSelect, ReusableTextField } from './Fields/AllFields';
import styles from "./AllowFeature.module.css";




const AllowFeature = ({ orgId, changeOrg, orgIds, featureAllowInfo }) => {

    const dispatch = useDispatch();
    const [featureType, setFeatureType] = useState(4);
    const [actiontype, setActiontype] = useState(0);

    const EnableOptions = [
        { value: 0, label: 'ENABLE' },
        { value: 1, label: 'ENABLE ALL' },
        { value: 2, label: 'DELETE' },
    ];

    const FeatureTypeOption = Object.entries(featureAllowInfo).map(([key, value]) => ({
        value: parseInt(value, 10),
        label: key.replace(/_/g, ' ') // Replace underscores with spaces for a more readable label
      }));

    const orgIdsOptions = orgIds.map((table) => ({
        value: table,
        label: table,
    }));

    const featureAllow = () => {
        let data = {
            org_id: orgId,
            feature_type: featureType,
        }
        dispatch(toggleLoader(true));
        axios.post(process.env.REACT_APP_FEATUREALLOW, data, { withCredentials: process.env.REACT_APP_CREDENTIALS })
            .then((res) => {
                dispatch(toggleLoader(false));
                toast.success("Success");
            }).catch((err) => {
                dispatch(toggleLoader(false));
                toast.error(err.message);
            })
    }

    const removeOrg = () => {
        let data = {
            org_id: orgId,
            feature_type: featureType,
            action_type: actiontype,
        }

        axios.delete(process.env.REACT_APP_FEATUREALLOW, {
            data
        })
            .then((res) => {
                dispatch(toggleLoader(false));
                toast.success("Success");
            }).catch((err) => {
                dispatch(toggleLoader(false));
                toast.error(err.message);
            })
    }

    const featureChange = () => {
        if (actiontype === 0) {
            featureAllow()
        }
        else removeOrg()
    }

    return (
        <Box className={styles.grid_container}>
            <Box className={styles.grid_item}>
                <Box className={styles.grid_text_label}>
                    <ReusableLabel text="Org Id" />
                    <ReusableTextField value={orgId} onChange={(e) => changeOrg(e.target.value)} placeholder='Enter Org Id' />
                </Box>
                <Box className={styles.grid_text_label}>
                    <ReusableLabel text="Feature Type" />
                    <ReusableSelect defaultValue={4} options={FeatureTypeOption}  value={featureType} onChange={(e) => setFeatureType(Number(e.target.value))} />
                </Box>
                <Box className={styles.grid_text_label}>
                    <ReusableLabel text="Action type" />
                    <ReusableSelect options={EnableOptions} value={actiontype} onChange={(e) => setActiontype(e.target.value)} />
                </Box>
                <Box sx={{ textAlign: 'end' }}>
                    <ReusableButton variant="contained" label={'Submit'} onClick={featureChange} />
                </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'end'}} className={styles.grid_item}>
                {/* <ReusableLabel text="Org Id" />
                <ReusableSelect options={orgIdsOptions} value={orgId} disabled={orgIds.length >= 1 ? false : true}
                    style={{ width: '150px' }}
                    onChange={(e) => changeOrg(e.target.value)} /> */}
            </Box>
        </Box>
    )
}

export default AllowFeature